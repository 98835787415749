import React from 'react';
import { EventData } from 'src/@types/event';
import { Card, Typography } from '@mui/material';
import { OfflineTicketType } from 'src/@types/OfflineTickets';
import useResponsive from 'src/hooks/useResponsive';
import OfflineTicketEditorMobile from './OfflineTicketEditorMobile';
import OfflineTicketEditorTable from './OfflineTicketEditorTable';
import useLocales from 'src/hooks/useLocales';
import axios from 'src/utils/axios';
import { useAppSelector } from 'src/redux/hook';
import { OptionsObject, enqueueSnackbar } from 'notistack';
import MultilingualName from 'src/components/MultilingualName';

interface OfflineTicketEditorProps {
  event: EventData;
  offlineTickets: OfflineTicketType[];
  setOfflineTickets: React.Dispatch<React.SetStateAction<OfflineTicketType[]>>;
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  setEditedOfflineTickets: React.Dispatch<React.SetStateAction<OfflineTicketType | undefined>>;
  setOpenNewOfflineTicketModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const OfflineTicketEditor: React.FC<OfflineTicketEditorProps> = ({
  event,
  offlineTickets,
  setOfflineTickets,
  setIsEditMode,
  setEditedOfflineTickets,
  setOpenNewOfflineTicketModal,
}) => {
  const { translate } = useLocales();
  const isMobile = useResponsive('down', 'sm');

  const currentOrganizer = useAppSelector((state) => state.userOrganizers.currentUserOrganizer);
  const currentRole = useAppSelector((state) => state.userRoles.currentUserRole);

  const handleDeleteOfflineTicketModal = (offlineTicketTypeId: number) =>
    setOfflineTickets((prev) =>
      prev.filter((offlineTicketType) => offlineTicketType.id !== offlineTicketTypeId)
    );

  const handleEditOfflineTicketModal = (
    event: React.MouseEvent<HTMLLIElement> | React.MouseEvent<HTMLButtonElement>,
    offlineTicket: OfflineTicketType
  ) => {
    event.preventDefault();
    setIsEditMode(true);
    setEditedOfflineTickets(offlineTicket);
    setOpenNewOfflineTicketModal(true);
  };

  const handleEditRow = (
    event: React.MouseEvent<HTMLLIElement> | React.MouseEvent<HTMLButtonElement>,
    offlineTicket: OfflineTicketType,
    setOpenMenuActions?: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  ) => {
    handleEditOfflineTicketModal(event, offlineTicket);
    if (setOpenMenuActions) {
      setOpenMenuActions(null);
    }
  };

  const deleteOfflineTicket = async (offlineTicket: OfflineTicketType) => {
    try {
      await axios.delete(
        `organizer/${currentOrganizer?.id}/event/${event?.id}/offline-ticket-type/${offlineTicket.id}`,
        {
          headers: {
            'organizer-id': currentOrganizer ? currentOrganizer.id.toString() : '',
            'role-id': currentRole ? currentRole.id.toString() : '',
          },
        }
      );

      const snackbarProps: OptionsObject = {
        variant: 'success',
        autoHideDuration: 1500,
      };

      handleDeleteOfflineTicketModal(offlineTicket.id);

      enqueueSnackbar(translate('ntak.attractionRegistration.data.saveSuccess'), snackbarProps);
    } catch (error) {
      console.error('Error submitting the report:', error);

      const snackbarProps: OptionsObject = {
        variant: 'error',
      };

      enqueueSnackbar(translate('ntak.attractionRegistration.data.saveFailed'), snackbarProps);
    }
  };

  return (
    <>
      <Typography variant="h5" mb={3}>
        <MultilingualName name={event.name} englishName={event.nameEnglish} />
      </Typography>
      <Card>
        {isMobile ? (
          <>
            {offlineTickets.map((offlineTicket) => (
              <OfflineTicketEditorMobile
                key={offlineTicket.id}
                offlineTicket={offlineTicket}
                handleEditRow={handleEditRow}
                deleteOfflineTicket={deleteOfflineTicket}
              />
            ))}
          </>
        ) : (
          <OfflineTicketEditorTable
            event={event}
            offlineTickets={offlineTickets}
            handleEditRow={handleEditRow}
            deleteOfflineTicket={deleteOfflineTicket}
          />
        )}
      </Card>
    </>
  );
};

export default OfflineTicketEditor;
