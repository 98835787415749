import { useState } from 'react';
import { alpha } from '@mui/material/styles';
import {
  Avatar,
  Typography,
  ListItemText,
  ListItemAvatar,
  MenuItem,
  Box,
  Tooltip,
} from '@mui/material';
import Iconify from '../../../components/Iconify';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import { AdminData } from 'src/@types/admin';
import _mock from 'src/_mock/_mock';
import useLocales from 'src/hooks/useLocales';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { admins } from 'src/constants/admins';

const ITEM_HEIGHT = 64;

type Props = {
  admin: AdminData;
};

export default function ContactsPopover({ admin }: Props) {
  const { translate, currentLang } = useLocales();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const [copiedEmail, setCopiedEmail] = useState(false);
  const [copiedPhoneNumber, setCopiedPhoneNumber] = useState(false);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const additionalAdminData = admins.find((adminData) => adminData.firstName === admin.name);
  const adminFullName =
    currentLang.value === 'hu'
      ? `${additionalAdminData?.lastName ? `${additionalAdminData.lastName} ` : ''}${admin.name}`
      : `${admin.name} ${additionalAdminData?.lastName ?? ''}`;

  const handleCopyEmailToClipboard = () => {
    navigator.clipboard.writeText(admin.email);
    setCopiedEmail(true);
    setTimeout(() => setCopiedEmail(false), 2000);
  };

  const handleCopyPhoneNumberToClipboard = () => {
    navigator.clipboard.writeText(admin.phoneNumber);
    setCopiedPhoneNumber(true);
    setTimeout(() => setCopiedPhoneNumber(false), 2000);
  };

  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <Iconify icon={'eva:people-fill'} width={20} height={20} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          minWidth: 290,
          '& .MuiMenuItem-root': {
            px: 1.5,
            height: ITEM_HEIGHT,
            borderRadius: 0.75,
          },
        }}
      >
        <Typography variant="h6" sx={{ p: 1.5 }}>
          {translate('contactPopover.contact')}
        </Typography>

        <MenuItem
          sx={{ height: 200, '&:hover': { backgroundColor: 'inherit', cursor: 'default' } }}
          style={{ height: 100 }}
          disableRipple
        >
          <ListItemAvatar sx={{ position: 'relative' }}>
            <Avatar
              src={additionalAdminData?.profileImg ?? '/'}
              alt={adminFullName}
              sx={{ width: 64, height: 64 }}
            />
          </ListItemAvatar>

          <ListItemText
            primaryTypographyProps={{ typography: 'subtitle1', mb: 0.25 }}
            secondaryTypographyProps={{ component: 'span', typography: 'body1' }}
            primary={adminFullName}
            secondary={
              <Box display="flex" flexDirection="column" gap={0.5}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  columnGap={1}
                >
                  {admin.email}
                  <Tooltip
                    enterTouchDelay={0}
                    arrow
                    title={
                      !copiedEmail
                        ? translate('contactPopover.copy')
                        : translate('contactPopover.copied')
                    }
                    placement="top"
                  >
                    <ContentCopyIcon
                      sx={{
                        width: 24,
                        paddingX: 0.5,
                        border: (theme) => `0.5px solid ${theme.palette.text.secondary}`,
                        borderRadius: 0.5,
                        cursor: 'pointer',
                      }}
                      onClick={handleCopyEmailToClipboard}
                    />
                  </Tooltip>
                </Box>
                {admin.phoneNumber && admin.name !== 'Balázs' && (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    columnGap={1}
                  >
                    {admin.phoneNumber}
                    <Tooltip
                      enterTouchDelay={0}
                      arrow
                      title={
                        !copiedPhoneNumber
                          ? translate('contactPopover.copy')
                          : translate('contactPopover.copied')
                      }
                      placement="top"
                    >
                      <ContentCopyIcon
                        sx={{
                          width: 24,
                          paddingX: 0.5,
                          border: (theme) => `0.5px solid ${theme.palette.text.secondary}`,
                          borderRadius: 0.5,
                          cursor: 'pointer',
                        }}
                        onClick={handleCopyPhoneNumberToClipboard}
                      />
                    </Tooltip>
                  </Box>
                )}
              </Box>
            }
          />
        </MenuItem>
      </MenuPopover>
    </>
  );
}
