import { NavConfig } from 'src/@types/menu';
import SvgIconStyle from '../../../components/SvgIconStyle';
import { PagePermissionEnum } from 'src/@types/auth';
import { FeatureFlags } from 'src/@types/types';

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  users: getIcon('ic_users'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  marketing: getIcon('ic_marketing'),
  history: getIcon('ic_history'),
  finance: getIcon('ic_finance'),
  newsletter: getIcon('ic_mail'),
  entry: getIcon('ic_entry'),
  ntak: getIcon('ic_ntak'),
  tickets: getIcon('ic_tickets'),
  event: getIcon('ic_event'),
  offer: getIcon('ic_offer'),
};

export const getNavConfig = (params?: FeatureFlags) => {
  const navConfig: NavConfig[] = [
    {
      subheader: 'menu.event.label',
      items: [
        {
          title: 'menu.event.event.label',
          path: '/dashboard/event',
          icon: ICONS.event,
          children: [
            {
              title: 'menu.event.event.create',
              path: '/dashboard/event/add',
              value: PagePermissionEnum.EVENT_CREATE,
            },
            {
              title: 'menu.event.event.list',
              path: '/dashboard/event/list',
              value: PagePermissionEnum.EVENT_LIST,
            },
            {
              title: 'menu.event.event.limit',
              path: '/dashboard/event/limit-management',
              value: PagePermissionEnum.EVENT_LIMIT_MANAGEMENT,
            },
            {
              title: 'menu.event.event.history',
              path: '/dashboard/event/history',
              value: PagePermissionEnum.EVENT_HISTORY,
            },
          ],
        },
        {
          title: 'menu.event.statistics.label',
          path: '/dashboard/statistics',
          icon: ICONS.analytics,
          children: [
            {
              title: 'menu.event.statistics.sales',
              path: '/dashboard/statistics/sales',
              value: PagePermissionEnum.STATISTICS_SALES,
            },
            {
              title: 'menu.event.statistics.report',
              path: '/dashboard/statistics/report',
              value: PagePermissionEnum.STATISTICS_REPORT,
            },
            {
              title: 'menu.event.statistics.customizedStatistics',
              path: '/dashboard/statistics/customized-statistics',
              value: PagePermissionEnum.STATISTICS_CUSTOMIZED,
            },
          ],
        },
        {
          title: 'menu.event.marketing.label',
          path: '/dashboard/marketing',
          icon: ICONS.marketing,
          disabledFeature: !params?.coupons.enabled,
          children: [
            {
              title: 'menu.event.marketing.coupon',
              path: '/dashboard/marketing/coupon',
              value: PagePermissionEnum.MARKETING_COUPON,
            },
          ],
        },
        {
          title: 'menu.event.finance.label',
          path: '/dashboard/finance',
          icon: ICONS.finance,
          children: [
            {
              title: 'menu.event.finance.settlement',
              path: '/dashboard/finance/settlement',
              value: PagePermissionEnum.SETTLEMENT,
            },
          ],
        },
        {
          title: 'menu.event.guestTicket.label',
          path: '/dashboard/event/guest',
          icon: ICONS.tickets,
          disabledFeature: !params?.guest_invitation.enabled,
          children: [
            {
              title: 'menu.event.guestTicket.invite',
              path: '/dashboard/event/guest/invite',
              value: PagePermissionEnum.GUESTS_INVITE,
            },
            {
              title: 'menu.event.guestTicket.list',
              path: '/dashboard/event/guest/list',
              value: PagePermissionEnum.GUESTS_LIST,
            },
            // {
            //   title: 'menu.event.guestTicket.limit',
            //   path: '/dashboard/event/guest/limit-management',
            //   value: PagePermissionEnum.GUESTS_LIMIT_MANAGEMENT,
            // },
            {
              title: 'menu.event.guestTicket.settings',
              path: '/dashboard/event/guest/settings',
              value: PagePermissionEnum.GUESTS_SETTINGS,
            },
          ],
        },
        {
          title: 'menu.event.boxoffice.label',
          path: '/dashboard/box-office',
          icon: ICONS.ecommerce,
          children: [
            {
              title: 'menu.event.boxoffice.events',
              path: '/dashboard/box-office/events',
              value: PagePermissionEnum.NTAK_BOXOFFICE_EVENTS,
            },
            {
              title: 'menu.event.boxoffice.settings',
              path: '/dashboard/box-office/settings',
              value: PagePermissionEnum.NTAK_BOXOFFICE_SETTINGS,
            },
          ],
        },
        // {
        //   title: 'menu.event.newsletter',
        //   path: '',
        //   icon: ICONS.newsletter,
        //   value: PagePermissionEnum.NEWSLETTER,
        // },
      ],
    },
    // {
    //   subheader: 'menu.entry.label',
    //   items: [
    //     {
    //       title: 'menu.entry.entrySystem.label',
    //       path: '',
    //       icon: ICONS.entry,
    //       children: [
    //         { title: 'menu.entry.entrySystem.list', path: '', value: PagePermissionEnum.ENTRY_LIST },
    //         { title: 'menu.entry.entrySystem.edit', path: '', value: PagePermissionEnum.ENTRY_EDIT },
    //         {
    //           title: 'menu.entry.entrySystem.delete',
    //           path: '',
    //           value: PagePermissionEnum.ENTRY_DELETE,
    //         },
    //       ],
    //     },
    //   ],
    // },
    {
      subheader: 'menu.ntak.label',
      items: [
        {
          title: 'menu.ntak.attraction.label',
          path: '/dashboard/ntak/attraction',
          icon: ICONS.ntak,
          children: [
            {
              title: 'menu.ntak.attraction.registration',
              path: '/dashboard/ntak/attraction/register',
              value: PagePermissionEnum.NTAK_EDIT_LIST,
            },
            {
              title: 'menu.ntak.attraction.list',
              path: '/dashboard/ntak/attraction/list',
              value: PagePermissionEnum.NTAK_EDIT_LIST,
            },
          ],
        },
      ],
    },
    {
      subheader: 'menu.accountSettings.label',
      items: [
        {
          title: 'menu.accountSettings.account.label',
          path: '/dashboard/account',
          icon: ICONS.user,
          children: [
            { title: 'menu.accountSettings.account.profile', path: '/dashboard/account/profile' },
            {
              title: 'menu.accountSettings.account.companyData',
              path: '/dashboard/account/company-data',
              value: PagePermissionEnum.ORGANIZER_DATA,
            },
          ],
        },
        {
          title: 'menu.accountSettings.invitedUsers.label',
          path: '/dashboard/invited-user',
          icon: ICONS.users,
          children: [
            {
              title: 'menu.accountSettings.invitedUsers.invite',
              path: '/dashboard/invited-user/invite',
              value: PagePermissionEnum.USER_INVITE,
            },
            {
              title: 'menu.accountSettings.invitedUsers.list',
              path: '/dashboard/invited-user/list',
              value: PagePermissionEnum.USER_LIST,
            },
            {
              title: 'menu.accountSettings.invitedUsers.history',
              path: '/dashboard/history',
              value: PagePermissionEnum.USER_HISTORY,
            },
          ],
        },
      ],
    },
    {
      subheader: 'menu.services.label',
      items: [{ title: 'menu.services.offers', path: '/dashboard/offer', icon: ICONS.offer }],
    },
  ];

  return navConfig;
};
