import { AdminData } from './admin';

export type UserInvitationData = {
  email: string;
  userId: string;
  organizerId?: number;
};

export type UserData = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  isEmailConfirmed: boolean;
  notRegistered: boolean;
  createdAt: Date;
  updatedAt: Date;
};

export type UserDataWithOrganizersAndRoles = UserData & {
  organizers?: OrganizerData[];
  roles: RoleData[];
};

export type RegistrationUserData = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password: string;
  language: string;
};

export type RequestedCompanyData = {
  taxNumber: string;
  companyName: string;
  postalCode: string;
  city: string;
  addressDetails: string;
};

export type CompanyAddress = {
  id: number;
  organizerId: number;
  country: string;
  postalCode: string;
  city: string;
  addressDetails: string;
  addressType: string;
};

export type OrganizerData = {
  id: number;
  userId: number;
  level: number | null;
  name: string;
  email: string;
  financeEmailAddress: string;
  contactEmailAddress: string;
  contactPhoneNumber: string;
  loginPassword: string;
  companyName: string;
  companyAddress: string;
  taxNumber: string;
  vatStatus: string;
  representativeName: string;
  bankAccountNumber: string;
  registeredNumber: string;
  contractForm: string;
  registerComment: string;
  extraField: string;
  commissionRate: string;
  currency: string;
  seoKeywords: string;
  seoDescription: string;
  backgroundColor: string;
  banner: string;
  link: string;
  handlingFeeFix: number;
  handlingFeePercent: string;
  handlingFeeSplitOrganizer: number;
  handlingFeeSplitTixa: number;
  guestTicketPrice: number;
  szamlazzhuUser: string;
  szamlazzhuPassword: string;
  szamlazzhuPrefix: string;
  szamlazzhuIs: boolean;
  simplePayMerchantId: string;
  simplePayMerchantKey: string;
  simplePayContractId: string;
  simplePayAdminId: number;
  tixaAdminName: string;
  adminData: AdminData | null;
  invoiceLastIssueDate: Date | null;
  settlementInternalComment: string;
  settlementExternalMessage: string;
  secondaryTicketing: boolean;
  checkboxIs: boolean;
  checkboxLabel: string;
  checkboxLabelEngilsh: string;
  checkboxText: string;
  checkboxTextEnglish: string;
  checkboxChecked: boolean;
  confirmationMailSubject: string;
  confirmationMailBody: string;
  confirmationMailSubjectEnglish: string;
  confirmationMailBodyEnglish: string;
  boxOfficeIs: boolean;
  boxOfficeCommissionFix: number;
  boxOfficeCommissionRate: string;
  offerPriceEditAllowIs: boolean;
  termsAndConditionsExtend: string;
  termsAndConditionsExtendEnglish: string;
  invoicingType: string | null;
  guestTicketEmailSubject: string;
  guestTicketEmailBody: string;
  guestTicketEmailSubjectEnglish: string;
  guestTicketEmailBodyEnglish: string;
  measuringCodeFacebookPixel: string;
  facebookToken: string;
  measuringCodeGoogleAnalitycs: string;
  googleConversionId: string;
  googleTagmanager: string;
  measuringCodeGoogleAnalitycsDomain: string;
  simplePayDeposit: boolean;
  pdfSendIs: boolean;
  postalServiceIs: boolean;
  businessInvoiceBanIs: boolean;
  paid: boolean;
  automaticInvoicingPeriod: string;
  automaticInvoicingPayDay: number;
  automaticInvoicingEmailAddress: string;
  automaticInvoicingRoundDay: number;
  automaticInvoicingDate: Date | null;
  automaticInvoicingNetOrGross: boolean;
  automaticInvoicingIs: boolean;
  isActive: boolean;
  url: string;
};

export type UserHistoryData = {
  id: number;
  organizerId: number;
  modifierUserName: string;
  modifierUserRole: string;
  modifierUserEmail: string;
  modifierUserPhone: string;
  modifiedUserName: string;
  modifiedUserRole: string;
  modifiedUserEmail: string;
  modifiedUserPhone: string;
  action: string;
  events: string;
  newRole: string;
  status: boolean;
  createdAt: Date;
  payload: string;
  response: string;
};

export type RoleData = {
  id: number;
  name: string;
  userId: number;
  organizerId: number;
  isOrganizer: boolean;
  isAllEvents: boolean;
  isConfirmed: boolean;
  userData?: UserData;
  organizerData?: OrganizerData;
  events: number[];
  permissions: number[];
};

export enum UserActionType {
  USER_INVITE = 'userInvite',
  USER_EDIT_ROLE = 'userEditRole',
  USER_ADD_ROLE = 'userAddRole',
}

export enum Currency {
  EUR = '€',
  HUF = 'Ft',
}
