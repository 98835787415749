import { ReactNode } from 'react';

import { IconButton } from '@mui/material';
import {
  SnackbarProvider as NotistackProvider,
  closeSnackbar,
  MaterialDesignContent,
} from 'notistack';
import Iconify from '../Iconify';
import useResponsive from 'src/hooks/useResponsive';
import { styled } from '@mui/material/styles';
import './styles/snackbar-styles.css';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    color: '#1e4620',
    backgroundColor: '#cae7ca',
  },
  '&.notistack-MuiContent-info': {
    color: '#014361',
    backgroundColor: '#d0eefb',
  },
  '&.notistack-MuiContent-warning': {
    color: '#663c00',
    backgroundColor: '#ffd599',
  },
  '&.notistack-MuiContent-error': {
    color: '#5f2120',
    backgroundColor: '#fad1d1',
  },
}));

type Props = {
  children: ReactNode;
};

export default function SnackbarProvider({ children }: Props) {
  const smDown = useResponsive('down', 'sm');

  return (
    <NotistackProvider
      maxSnack={3}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      classes={{ containerRoot: smDown ? 'container-mobile' : 'container-desktop' }}
      preventDuplicate={true}
      Components={{
        success: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
      }}
      iconVariant={{
        success: (
          <Iconify
            width={25}
            fontSize={20}
            icon="eva:checkmark-circle-outline"
            sx={{ marginRight: 1 }}
          />
        ),
        warning: (
          <Iconify
            width={25}
            fontSize={20}
            icon="eva:alert-triangle-outline"
            sx={{ marginRight: 1 }}
          />
        ),
        error: (
          <Iconify
            width={25}
            fontSize={20}
            icon="eva:alert-circle-outline"
            sx={{ marginRight: 1 }}
          />
        ),
      }}
      style={{
        minWidth: 0,
        maxWidth: '45rem',
        flexWrap: 'nowrap',
        alignItems: 'center',
        boxShadow: '0px 0px 10px 3px rgba(0,0,0,0.05)',
      }}
      action={(snackbarId) => (
        <IconButton onClick={() => closeSnackbar(snackbarId)}>
          <Iconify icon={'eva:close-circle-outline'} />
        </IconButton>
      )}
    >
      {children}
    </NotistackProvider>
  );
}
