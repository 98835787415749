import {
  Box,
  IconButton,
  Dialog,
  Slide,
  DialogContent,
  Typography,
  FormHelperText,
} from '@mui/material';
import { Stack } from '@mui/system';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import CloseIcon from '@mui/icons-material/Close';
import useResponsive from 'src/hooks/useResponsive';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { ReactNode, forwardRef, useEffect, useState } from 'react';
import { TransitionProps } from 'notistack';
import MultilingualName from './MultilingualName';
import useLocales from 'src/hooks/useLocales';
import { fDateTimeSuffixEn, fDateTimeSuffixHu } from 'src/utils/format-time';
import { utcToZonedTime } from 'date-fns-tz';

type Props = {
  open: boolean;
  testId?: string;
  defaultFullscreen?: boolean;
  children: ReactNode;
  dialogActions?: ReactNode;
  onHandleClose?: VoidFunction;
  title?: string;
  eventName?: string;
  eventNameEnglish?: string;
  eventStartDate?: string;
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomizedModal({
  open,
  testId,
  children,
  defaultFullscreen = false,
  dialogActions,
  onHandleClose,
  title,
  eventName,
  eventNameEnglish,
  eventStartDate,
}: Props) {
  const { currentLang } = useLocales();
  const smDown = useResponsive('down', 'sm');

  const [fullscreen, setFullscreen] = useState(defaultFullscreen);

  const toggleFullscreen = () => {
    setFullscreen((prev) => !prev);
  };

  useEffect(() => setFullscreen(defaultFullscreen), [defaultFullscreen]);

  return (
    <Dialog
      open={open}
      onClose={onHandleClose}
      data-testid={testId}
      fullWidth
      scroll="paper"
      fullScreen={fullscreen}
      TransitionComponent={defaultFullscreen ? Transition : undefined}
      sx={{ '& .MuiPaper-root': { maring: 0 } }}
    >
      <DialogContent sx={{ padding: 0 }}>
        <Box>
          {onHandleClose && (
            <Stack width="100%" position="absolute">
              <IconButton
                onClick={onHandleClose}
                style={{
                  alignSelf: 'flex-end',
                  marginTop: 8,
                  marginRight: 16,
                  position: 'sticky',
                  zIndex: 999,
                  display: '',
                }}
              >
                <CloseIcon />
              </IconButton>
              {!defaultFullscreen && smDown && (
                <IconButton
                  onClick={toggleFullscreen}
                  style={{
                    alignSelf: 'flex-end',
                    marginTop: 8,
                    marginRight: 48,
                    position: 'absolute',
                    zIndex: 999,
                  }}
                >
                  {fullscreen ? (
                    <CloseFullscreenIcon fontSize="small" />
                  ) : (
                    <OpenInFullIcon fontSize="small" />
                  )}
                </IconButton>
              )}
            </Stack>
          )}
          <SimpleBar autoHide={false} forceVisible style={{ maxHeight: 'inherit' }}>
            <Box p={3} sx={{ marginTop: '24px !important' }}>
              {title && (
                <Typography variant="h5" mb={eventName || eventStartDate ? 0 : 3}>
                  {title}
                </Typography>
              )}
              <Box mb={3}>
                {eventName && (
                  <FormHelperText sx={{ margin: 0 }}>
                    <MultilingualName name={eventName} englishName={eventNameEnglish ?? ''} />
                  </FormHelperText>
                )}
                {eventStartDate && (
                  <FormHelperText sx={{ margin: 0 }}>
                    {currentLang.value === 'hu'
                      ? fDateTimeSuffixHu(utcToZonedTime(eventStartDate, 'UTC'))
                      : fDateTimeSuffixEn(utcToZonedTime(eventStartDate, 'UTC'))}
                  </FormHelperText>
                )}
              </Box>

              {children}
            </Box>
          </SimpleBar>
        </Box>
      </DialogContent>

      {dialogActions}
    </Dialog>
  );
}
