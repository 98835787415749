import { RoleData, OrganizerData, UserDataWithOrganizersAndRoles } from '../../@types/user';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import {
  EventData,
  EventDataWithStatistics,
  EventDataWithSoldTickeQty,
  EventDataWithDetailedStatistics,
} from 'src/@types/event';
import { PATH_AUTH } from 'src/routes/paths';
import { PaymentStatus, SettlementListResponse } from 'src/@types/settlement';
import { socket } from 'src/services/socket/socket.io';
import { handleRefreshToken } from 'src/utils/jwt';
import { userRolesActions } from '../user-roles';
import { getPermissionsByRoles } from 'src/utils/permissions-by-roles';
import axios from 'src/utils/axios';
import { appEnvVariables, featureFlags } from 'src/config';
import { Environment, FeatureFlags } from 'src/@types/types';
import {
  Coupon,
  EventCouponStatistics,
  CouponCodeResponse,
  CouponStatisticsGraphResponse,
} from 'src/@types/coupon';
import { CustomizedStatistics } from 'src/@types/customized-statistics';
import {
  GuestHistory,
  GuestHistoryAction,
  GuestInvitationWithRelatedData,
} from 'src/@types/guests';

type UserQueryProps = {
  organizerId?: number;
  skip: number;
  rowsPerPage: number;
  orderBy: string;
  order: string;
  filterRole?: string;
  searchQuery: string;
  roles?: boolean;
};

type EventQueryProps = {
  organizerId?: number;
  roleId?: number;
  skip: number;
  rowsPerPage: number;
  orderBy: string;
  order: string;
  filterStatus: string;
  searchQuery: string;
  url?: boolean;
  guestCount?: boolean;
  ticketTypes?: boolean;
  offlineTicketTypes?: boolean;
};

type EventStatisticsQueryProps = {
  eventId?: number;
  organizerId?: number;
  roleId?: number;
  timePeriod: string;
  filterFromDate?: string;
  filterToDate?: string;
};

type EventsStatisticsQueryProps = {
  organizerId?: number;
  roleId?: number;
  skip: number;
  rowsPerPage: number;
  orderBy: string;
  order: string;
  filterStatus: string;
  timePeriod: string;
  filterFromDate?: string;
  filterToDate?: string;
  searchQuery: string;
};

type SettlementQueryProps = {
  organizerId?: number;
  orderBy: string;
  order: string;
  paymentStatus?: PaymentStatus;
  filterFromDate?: string;
  filterToDate?: string;
  events?: number[];
};

type CouponsQueryProps = {
  organizerId?: number;
  skip: number;
  rowsPerPage: number;
  orderBy: string;
  order: string;
  filterStatus: string;
  searchText: string;
};

type CouponStatisticsQueryProps = {
  organizerId?: number;
  couponId?: number;
  skip: number;
  rowsPerPage: number;
  orderBy: string;
  order: string;
  filterFromDate?: string;
  filterToDate?: string;
};

type CouponStatisticsGraphQueryProps = {
  organizerId?: number;
  couponId: number;
  filterFromDate?: string;
  filterToDate?: string;
};

type CouponCodesQueryProps = {
  organizerId?: number;
  couponId: number;
  skip: number;
  rowsPerPage: number;
  isActive: boolean;
  searchText: string;
};

type CustomizedStatisticsQueryProps = {
  organizerId?: number;
  skip: number;
  rowsPerPage: number;
  orderBy: string;
  order: string;
  searchText: string;
};

type GuestlistQueryProps = {
  organizerId?: number;
  roleId?: number;
  skip: number;
  rowsPerPage: number;
  searchText: string;
  userIds?: number[];
  eventIds?: number[];
};

type GuestHistoryQueryProps = {
  organizerId?: number;
  eventId: number;
  guestInvitationId: number;
  skip: number;
  rowsPerPage: number;
  action?: GuestHistoryAction;
  searchText: string;
};

const paramsSerializer = (params: Record<string, any>) => {
  const queryParams = new URLSearchParams();

  for (const key in params) {
    const value = params[key];

    if (Array.isArray(value)) {
      value.forEach((item) => {
        queryParams.append(`${key}[]`, item);
      });
    } else {
      typeof value !== 'undefined' && queryParams.append(key, value);
    }
  }

  return queryParams.toString();
};

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_HOST_API_KEY,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem('accessToken');

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  },
  paramsSerializer,
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    const refreshToken = localStorage.getItem('refreshToken');

    if (refreshToken) {
      await handleRefreshToken({ refreshToken });

      result = await baseQuery(args, api, extraOptions);
    } else {
      window.location.href = PATH_AUTH.login;
    }
  }

  return result;
};

export const userApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'User',
    'Event',
    'EventWithTicketType',
    'EventWithStatistics',
    'EventCouponStatistics',
    'SettlementList',
    'CouponCodeList',
    'Guestlist',
    'GuestHistory',
  ],
  endpoints: (build) => ({
    getFeatureFlags: build.query<{ [key: string]: { enabled: boolean } }, { organizerId?: number }>(
      {
        queryFn: async ({ organizerId }) => {
          if (appEnvVariables.environment === Environment.LOCAL) {
            return { data: featureFlags.localOverrides };
          }

          try {
            const { data } = await axios.get<FeatureFlags>(
              `${featureFlags.apiHost}/configurations/feature-toggles`,
              {
                headers: {
                  'X-API-Key': featureFlags.apiKey || '',
                },
                params: {
                  organizerId,
                },
              }
            );

            return { data };
          } catch (error) {
            throw new Error(error);
          }
        },
      }
    ),
    getOrganizers: build.query<OrganizerData[], any>({
      query: (userId) => `user/${userId}/organizer`,
      async onCacheEntryAdded(arg, { updateCachedData, cacheEntryRemoved }) {
        try {
          socket.on('onUserOrganizers', (data) => {
            updateCachedData(() => data);
          });

          await cacheEntryRemoved;

          socket.off('connect', () => {
            console.log('Disconnect');
          });
          socket.off('onUserOrganizers', () => {
            console.log('Disconnect organizers');
          });
        } catch (error) {
          console.error(error);
        }
      },
    }),
    getRoles: build.query<RoleData[], any>({
      query: ({ userId, organizerId }) => ({
        url: `user/${userId}/role/list`,
        params: {
          organizerId,
        },
      }),

      async onCacheEntryAdded(arg, { dispatch, updateCachedData, cacheEntryRemoved }) {
        try {
          socket.on('onUserRoles', (data: RoleData[]) => {
            const { setPermissionsByRoles } = userRolesActions;

            const permissionsByRoles = getPermissionsByRoles(data);

            dispatch(setPermissionsByRoles(permissionsByRoles));
            dispatch(
              userApi.util.invalidateTags([
                'Event',
                'EventWithTicketType',
                'EventWithStatistics',
                'SettlementList',
              ])
            );

            updateCachedData(() => data);
          });

          await cacheEntryRemoved;

          socket.off('connect', () => {
            console.log('Disconnect');
          });
          socket.off('onUserRoles', () => {
            console.log('Disconnect user roles');
          });
        } catch (error) {
          console.error(error);
        }
      },
    }),
    getUsersByOrganizer: build.query<
      { data: UserDataWithOrganizersAndRoles[]; total: number },
      UserQueryProps
    >({
      query: ({ organizerId, ...queries }) => ({
        url: `organizer/${organizerId}/user`,
        params: queries,
      }),
      providesTags: ['User'],
    }),
    getEventsByOrganizer: build.query<{ data: EventData[]; total: number }, EventQueryProps>({
      query: ({ organizerId, ...queries }) => ({
        url: `organizer/${organizerId}/event`,
        params: queries,
      }),
      providesTags: ['Event'],
    }),
    getEventsWithTicketTypesByOrganizer: build.query<
      { data: EventDataWithSoldTickeQty[]; total: number },
      Omit<EventQueryProps, 'filterStatus' | 'offlineTicketTypes'>
    >({
      query: ({ organizerId, roleId, ...queries }) => ({
        headers: {
          'role-id': roleId ? roleId.toString() : '',
          'organizer-id': organizerId ? organizerId.toString() : '',
        },
        url: `organizer/${organizerId}/limit-management`,
        params: queries,
      }),
      providesTags: ['EventWithTicketType'],
    }),
    getEventWithStatistics: build.query<EventDataWithDetailedStatistics, EventStatisticsQueryProps>(
      {
        query: ({ eventId, organizerId, roleId, ...queries }) => ({
          headers: {
            'role-id': roleId ? roleId.toString() : '',
            'organizer-id': organizerId ? organizerId.toString() : '',
          },
          url: `organizer/${organizerId}/event/${eventId}/statistics`,
          params: queries,
        }),
        providesTags: ['EventWithStatistics'],
      }
    ),
    getEventsWithStatisticsByOrganizer: build.query<
      { data: EventDataWithStatistics[]; total: number },
      EventsStatisticsQueryProps
    >({
      query: ({ organizerId, roleId, ...queries }) => ({
        headers: {
          'role-id': roleId ? roleId.toString() : '',
          'organizer-id': organizerId ? organizerId.toString() : '',
        },
        url: `organizer/${organizerId}/event/statistics`,
        params: queries,
      }),
      providesTags: ['EventWithStatistics'],
    }),
    getEventCouponStatistics: build.query<
      { data: EventCouponStatistics[]; total: number },
      CouponStatisticsQueryProps
    >({
      query: ({ organizerId, couponId, ...queries }) => ({
        url: `organizer/${organizerId}/coupon/${couponId}/coupon-statistics/events`,
        params: queries,
      }),
      providesTags: ['EventCouponStatistics'],
    }),
    getCouponStatisticsGraph: build.query<
      CouponStatisticsGraphResponse,
      CouponStatisticsGraphQueryProps
    >({
      query: ({ organizerId, couponId, ...queries }) => ({
        url: `organizer/${organizerId}/coupon/${couponId}/coupon-statistics`,
        params: queries,
      }),
    }),
    getSettlementList: build.query<SettlementListResponse, SettlementQueryProps>({
      query: ({ organizerId, ...queries }) => ({
        url: `organizer/${organizerId}/event/settlement`,
        params: queries,
      }),
      providesTags: ['SettlementList'],
    }),
    getCouponsList: build.query<{ data: Coupon[]; total: number }, CouponsQueryProps>({
      query: ({ organizerId, ...queries }) => ({
        url: `organizer/${organizerId}/coupon`,
        params: queries,
      }),
    }),
    getCouponCodesList: build.query<
      { data: CouponCodeResponse[]; total: number },
      CouponCodesQueryProps
    >({
      query: ({ organizerId, couponId, ...queries }) => ({
        url: `organizer/${organizerId}/coupon/${couponId}/code`,
        params: queries,
      }),
      providesTags: ['CouponCodeList'],
    }),
    getCustomizedStatisticsList: build.query<
      { data: CustomizedStatistics[]; total: number },
      CustomizedStatisticsQueryProps
    >({
      query: ({ organizerId, ...queries }) => ({
        url: `organizer/${organizerId}/customized-statistics`,
        params: queries,
      }),
    }),
    getGuestlist: build.query<
      { data: GuestInvitationWithRelatedData[]; total: number },
      GuestlistQueryProps
    >({
      query: ({ organizerId, ...queries }) => ({
        url: `organizer/${organizerId}/event/guest-invitation`,
        params: queries,
      }),
      providesTags: ['Guestlist'],
    }),
    getGuestHistory: build.query<{ data: GuestHistory[]; total: number }, GuestHistoryQueryProps>({
      query: ({ organizerId, eventId, guestInvitationId, ...queries }) => ({
        url: `organizer/${organizerId}/event/${eventId}/guest-invitation/${guestInvitationId}/history`,
        params: queries,
      }),
      providesTags: ['GuestHistory'],
    }),
  }),
});

export const {
  usePrefetch,
  useGetOrganizersQuery,
  useGetRolesQuery,
  useGetUsersByOrganizerQuery,
  useGetEventsByOrganizerQuery,
  useGetEventsWithTicketTypesByOrganizerQuery,
  useGetEventWithStatisticsQuery,
  useGetEventsWithStatisticsByOrganizerQuery,
  useGetEventCouponStatisticsQuery,
  useGetSettlementListQuery,
  useGetFeatureFlagsQuery,
  useGetCouponsListQuery,
  useGetCouponCodesListQuery,
  useGetCustomizedStatisticsListQuery,
  useGetCouponStatisticsGraphQuery,
  useGetGuestlistQuery,
  useGetGuestHistoryQuery,
} = userApi;
