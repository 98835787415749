import React, { ReactNode, useState } from 'react';
import useLocales from 'src/hooks/useLocales';
import { Divider, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { OfflineTicketType } from 'src/@types/OfflineTickets';
import DeleteDialog from 'src/sections/@dashboard/event/new-event/DeleteDialog';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AmountWithCurrency from 'src/components/AmountWithCurrency';
interface OfflineTicketEditorMobileProps {
  offlineTicket: OfflineTicketType;
  handleEditRow: (
    event: React.MouseEvent<HTMLButtonElement>,
    offlineTicket: OfflineTicketType
  ) => void;
  deleteOfflineTicket: (offlineTicket: OfflineTicketType) => void;
}

const OfflineTicketEditorMobile: React.FC<OfflineTicketEditorMobileProps> = ({
  offlineTicket,
  handleEditRow,
  deleteOfflineTicket,
}) => {
  const { translate } = useLocales();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteDialogContent, setDeleteDialogContent] = useState<string | ReactNode>('');

  const HandleDeleteRow = (offlineTicket: OfflineTicketType) => {
    deleteOfflineTicket(offlineTicket);
    setOpenDeleteDialog(false);
  };

  const handleOpenDeleteDialog = (offlineTicket: OfflineTicketType) => {
    setDeleteDialogContent(
      translate('ntak.boxoffice.offlineTicket.actions.ticketDeletionAllowed', {
        ticketName: offlineTicket.ticketTypeName,
      })
    );
    setOpenDeleteDialog(true);
  };

  return (
    <>
      <Grid container padding="8px 8px 8px 16px" alignItems="center">
        <Grid item xs={6}>
          <Typography variant="subtitle1">{offlineTicket.ticketTypeName}</Typography>
          <Stack flexDirection="row" display="flex" alignItems="center">
            <AmountWithCurrency variant="body2" amount={offlineTicket.ticketPrice} />
            {offlineTicket.limit && (
              <>
                <Typography variant="body2" style={{ margin: '0 4px' }}>
                  &middot;
                </Typography>
                <Typography variant="body2">{offlineTicket.limit} db</Typography>
              </>
            )}
          </Stack>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          <Stack flexDirection="row" justifyContent="space-evenly">
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={15000}
              title={translate('ntak.boxoffice.offlineTicket.actions.edit')}
              placement="top"
            >
              <IconButton onClick={(event) => handleEditRow(event, offlineTicket)}>
                <EditOutlinedIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={15000}
              title={translate('ntak.boxoffice.offlineTicket.actions.delete')}
              placement="top"
            >
              <IconButton onClick={() => handleOpenDeleteDialog(offlineTicket)}>
                <DeleteOutlinedIcon fontSize="medium" color="error" />
              </IconButton>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
      <Divider />
      <DeleteDialog
        open={openDeleteDialog}
        dataTestId={'deleteDialog'}
        onClose={() => setOpenDeleteDialog(false)}
        deleteDialogContent={deleteDialogContent}
        isDeleteAllowed={true}
        handleDelete={() => HandleDeleteRow(offlineTicket)}
      />
    </>
  );
};

export default OfflineTicketEditorMobile;
