const hu = {
  login: {
    welcomeMessage: 'Szia, Üdv újra itt',
    dontHaveAccount: 'Még nem regisztráltál? ',
    getStarted: 'Vágjunk bele!',
    header: 'Lépj be a Tixa Adminba',
    details: 'Add meg az adataid.',
    email: 'Email cím',
    password: 'Jelszó',
    staySignedIn: 'Maradj bejelentkezve',
    forgotPassword: 'Elfelejtett jelszó?',
    login: 'Bejelentkezés',
    errorMsg: {
      required: '{{ fieldNameHu }} megadása kötelező.',
      validEmail: 'Email cím formátuma nem megfelelő.',
    },
  },
  register: {
    welcomeMessage: 'Vedd kezedbe az irányítást a Tixával',
    haveAccount: 'Már regisztráltál? ',
    signIn: 'Lépj be!',
    header: 'Vágj bele teljesen ingyen',
    details: 'Regisztrálj egyszerűen.',
    firstName: 'Keresztnév',
    lastName: 'Vezetéknév',
    email: 'Email cím',
    phoneNumber: 'Telefonszám',
    password: 'Jelszó',
    confirmPassword: 'Jelszó megerősítése',
    register: 'Regisztráció',
    confirmationText: 'A regisztrációval elfogadod a Tixa ',
    termsOfService: 'Felhasználási Feltételeit',
    link: 'és az',
    privacyPolicy: 'Adatvédelmi Szabályzatot',
    errorMsg: {
      required: '{{ fieldNameHu }} megadása kötelező.',
      firstNameLength: 'A keresztnévnek minimum 3 karakternek kell lennie.',
      lastNameLength: 'A vezetéknévnek minimum 3 karakternek kell lennie.',
      validEmail: 'Email cím formátuma nem megfelelő.',
      validPhoneNumber: 'Telefonszám formátuma nem megfelelő.',
      passwordLength: 'A jelszónak minimum 6 karakternek kell lennie.',
      passwordMatch: 'Nem egyezik meg a két jelszó.',
    },
  },
  home: {
    content:
      'Ezt az oldalt azért alkottuk, hogy egyszerűen létrehozhasd rendezvényeid, illetve menedzselhesd azokat. Hajrá, hozzunk létre emlékezetes eseményeket!',
    tixaTeam: 'A Tixa csapata',
  },

  eventNewEdit: {
    newEventTitle: 'Új rendezvény',
    editEventTitle: 'Rendezvény szerkesztése',
    breadcrumb: {
      dashboard: 'Vezérlőpult',
      event: 'Rendezvény',
      newEvent: 'Új rendezvény',
      editEvent: 'Szerkesztés',
    },
    stepper: {
      basicData: 'Alapadatok',
      location: 'Helyszín',
      ticket: 'Jegy',
      picture: 'Képek',
      description: 'Leírás',
      preview: 'Előnézet',
    },
    basicData: {
      namePlaceholder: 'Rendezvény',
      form: {
        name: 'Név *',
        nameEn: 'Angol név',
        editNameEn: 'Angol név szerkesztése',
        vatStatus: 'ÁFA *',
        date: 'Rendezvény kezdete {{ required }}',
        doorOpening: 'Kapunyitás',
        eventEnd: 'Rendezvény vége {{ required }}',
        editSalesDate: 'Értékesítési dátum szerkesztése',
        salesStart: 'Értékesítés kezdete {{ required }}',
        salesEnd: 'Értékesítés vége {{ required }}',
      },
      toggleGroup: {
        settings: 'Beállítások',
        nameEn: 'Angol név',
        doorOpening: 'Kapunyitás',
        sales: 'Értékesítés',
        vat: 'ÁFA',
      },
      diffSalesAndEventEndMsg:
        'A rendezvény vége és az értékesítése vége közötti különbség több, mint 24 óra.',
      diffDateTextMsg:
        'A jegyoldalon megjelenő dátum nem frissült. A "Speciális beállitások" (Előnézet) menüpont alatt tudod módosítani.',
      uniqueLinkMsg:
        'A rendezvényed linkjének egyedinek kell lennie. A "Speciális beállitások" (Előnézet) menüpont alatt tudod módosítani.',
    },
    location: {
      location: 'Helyszín {{ required }}',
      addNewLocation: '+ Új helyszín hozzáadása',
      form: {
        header: 'Új helyszín',
        name: 'Név *',
        addressHeader: 'Cím',
        country: 'Ország',
        countryHu: 'Magyarország',
        postalCode: 'Irányítószám *',
        locality: 'Helység *',
        addressDetails: 'Cím *',
        save: 'Mentés',
        cancel: 'Mégse',
        errorMsg: {
          required: '{{ fieldNameHu }} megadása kötelező.',
          createFailed: 'Helyszín létrehozása sikertelen.',
        },
      },
    },
    ticket: {
      deleteDialog: {
        title: 'Törlés megerősítése',
        back: 'Mégse',
        confirm: 'Törlés',
        ok: 'Ok',
      },
      ticketHelp: {
        ticketHelp: 'Hogyan állítsam be jegyeimet?',
      },
      ticketType: {
        newTicketType: 'Új jegytípus',

        form: {
          newHeader: 'Új jegytípus',
          editHeader: 'Jegytípus szerkesztése',
          name: 'Név',
          enName: 'Név angolul',
          editEnName: 'Angol név szerkesztése',
          save: 'Mentés',
          cancel: 'Mégse',
          errorMsg: {
            required: '{{ fieldNameHu }} megadása kötelező.',
            createTicketTypeFailed: 'Jegytípus létrehozása sikertelen.',
            updateTicketTypeFailed: 'Jegytípus módosítása sikertelen.',
          },
        },
        edit: {
          tooltip: 'Szerkesztés',
        },
        delete: {
          tooltip: 'Törlés',
          deletionAllowed:
            "A következő jegytípust szeretnéd törölni: '{{ ticketTypeName }}'. Biztos vagy benne?",
          deletionDenied: `Nem törölheted az utolsó jegytípust. <1>Szerkesztheted</1> vagy <3>létrehozhatsz</3> egy újat.`,
          deleteTicketTypeFailed: 'Jegytípus törlése sikertelen.',
        },
      },
      offer: {
        newOffer: 'Új jegyár',
        form: {
          newHeader: 'Új jegyár',
          editHeader: 'Jegyár szerkesztése',
          editheader: 'Jegyár szerkesztése',
          name: 'Név',
          namePlaceholder: 'early bird, normál, stb.',
          enName: 'Angol név',
          enNamePlaceholder: 'early bird, normal, stb.',
          price: 'Ár *',
          quantity: 'Darabszám *',
          cartLimit: 'Kosár limit',
          boxOfficeLimit: 'Pénztár limit',
          editSalesDate: 'Értékesítési dátum szerkesztése',
          salesStart: 'Értékesítés kezdete',
          salesEnd: 'Értékesítés vége',
          sold: 'Eladott',
          info: 'Ha a tételt szeretnéd megbontani a számlán, jelezd a kapcsolattartódnak.',
          save: 'Mentés',
          cancel: 'Mégse',
          errorMsg: {
            required: '{{ fieldNameHu }} megadása kötelező.',
            minQuantity: 'A darabszám nem lehet 0.',
            maxQuantity: 'A darabszám nem lehet nagyobb, mint 100.',
            minCartLimit: 'A kosár limit nem lehet 0.',
            createOfferFailed: 'Jegyár létrehozása sikertelen.',
            updateOfferFailed: 'Jegyár módosítása sikertelen.',
            salesStartMin:
              'Az értékesítés kezdete nem lehet korábbi dátum, mint a globális értékesítés kezdete {{ salesStartDate }}.',
            salesEndMax:
              'Az értékesítés vége csak a globális értékesítés végéig lehetséges {{ salesEndDate }}.',
          },
        },
        edit: 'Szerkesztés',
        lock: 'A jegyár feloldását kérd a kapcsolattartódtól.',
        delete: {
          tooltip: 'Törlés',
          deletionAllowed:
            "A következő jegyárat szeretnéd törölni: '{{ offerName }}'. Biztos vagy benne?",
          deletionDenied: `Nem törölheted az utolsó jegyárat. <1>Szerkesztheted</1> vagy <3>létrehozhatsz</3> egy újat.`,
          deleteOfferFailed: 'Jegyár törlése sikertelen.',
          deleteSoldOfferFailed: 'A már értékesített jegyárak nem törölhetőek.',
        },
        show: 'Mutat',
        hide: 'Elrejt',
        zeroOfferPriceMsg: 'Jegyár értéke nulla',
      },
      totalQty: 'Összesen:',
    },
    pictures: {
      coverImg: 'Borítókép',
      coverImgInfo: 'Ideális képméret: 1000x650, max. fájlméret: 8MB, fájltípus: JPG, JPEG, PNG',
      gallery: 'Galéria',
      galleryInfo: 'max. 4 kép, fájltípus: JPG, JPEG, PNG',
      ticketImg: 'Jegy fejléc',
      ticketImgTooltip:
        'A PDF jegy bal felső sarkában levő 10x3 cm méretű felület, amelyet opcionálisan használhatsz grafikai anyag elhelyezésére.',

      ticketImgInfo: 'Ideális képméret 10x3cm, 150-300 dpi, fájltípus: JPG, JPEG, PNG',
      ticketTypeImgs: 'Jegy fejléc - jegytípusok',
      ticketTypeImgsTooltip:
        'A PDF jegy bal felső sarkában levő 10x3 cm méretű felület, amelyet opcionálisan használhatsz grafikai anyag elhelyezésére. Akár jegytípusonként is változtatható, így megjelenítheted a jegytípus elnevezését kiemelten vagy a jegytípusra jellemző speciális információt adhatsz meg.',
      uploadImg: {
        header: 'Képfeltöltés',
        description: 'Húzd ide a fájlt vagy <1>töltsd fel az eszközödről</1>',
        timeInfo: 'A képfeltöltés több időt is igénybe vehet.',
      },
      cropImg: {
        header: 'Kép szerkesztése',
        crop: 'Szerkesztés',
        cancel: 'Mégse',
      },
      generalTicketImg: 'Általános',
      ticketPreview: {
        tooltip: 'Jegyminta megtekintése',
      },
      ticketImgDelete: {
        tooltip: 'Fejléc törlése',
      },
      errorMsg: {
        'aspect-ratio-error': 'Érvénytelen képarány vagy minőség.',
        'file-too-large': 'Kép mérete nagyobb a megengedettnél.',
        'file-invalid-type': 'Érvénytelen fájltípus. Csak JPG, JPEG vagy PNG.',
        uploadFailed: 'Kép feltöltése sikertelen.',
        cropFailed: 'Kép szerkesztése sikertelen.',
        deleteFailed: 'Kép törlése sikertelen.',
        downloadTicketPreviewFailed: 'Jegy előnézet letöltése sikertelen.',
      },
    },
    description: {
      huDescription: {
        header: 'Magyar leírás',
        placeholder: 'Rendezvény leírása...',
      },
      videoLink: 'YouTube link',
      videoLinkPlaceholder: 'A YouTube linket ide másold a videó beágyazásához.',
      seoDescription: 'SEO leírás',
      seoDescriptionInfo:
        'Ez a meta leírás, a keresőmotor találatainál a cím alatt megjelenő szöveg.',
      addEnDescription: 'Angol leírás szerkesztése',
      enDescription: {
        header: 'Angol leírás',
        placeholder: 'Rendezvény angol leírása...',
      },
    },
    preview: {
      specialSettings: {
        specialSettings: 'Speciális beállítások',
        form: {
          header: 'Speciális beállítások',
          url: 'Link {{ required }}',
          dateWithText: 'Megjelenő dátum',
          dateWithTextEn: 'Megjelenő dátum angolul',
          ntakEventId: 'NTAK program azonosító',
          ntakInfo:
            'Ha egyszerre több szolgáltatónál is értékesíted a rendezvényt, akkor mindenhol meg kell adnod egy egyedi azonosítót, ami alapján az NTAK be tudja azonosítani, hogy ugyanarról a rendezvényről van szó.',
          ageLimit: 'Hány éves kortól lehet részt venni?',
          ageLimitFree: 'Hány éves korig ingyenes?',
          age: 'Életkor',
          secret: 'Titkos',
          secretInfo: 'A rendezvényt csak a linkkel rendelkezők érik el.',
          save: 'Mentés',
          cancel: 'Mégse',
        },
      },
      gateTime: 'Kapunyitás',
      free: 'Ingyenes',
      sold: 'Elfogyott',
      inactivePrice: 'Nem aktív ár',
      publicSuccessDialog: {
        title: 'Sikeres publikálás',
        content: 'Sikeresen publikáltad a rendezvényt. Mostantól elérhető a Tixa oldalán!',
        ok: 'Ok',
      },
    },
    requiredFields: {
      title: 'Kötelező adatok publikáláshoz',
    },
    backToListBtn: 'Vissza a listához',
    backToListMobileBtn: 'Lista',
    backBtn: 'Vissza',
    nextBtn: 'Tovább',
    public: 'Publikálás',
    errorMsg: {
      required: '{{ fieldNameHu }} megadása kötelező.',
      invalidDate: 'Érvénytelen dátum',
      eventStart: 'A rendezvény kezdete nem lehet korábbi a mai napnál.',
      eventEndPast: 'A rendezvény vége nem lehet korábbi a mai napnál.',
      eventEndMinDate: 'A rendezvény vége nem lehet korábban, mint a rendezvény kezdete.',
      salesStartMin: 'Az értékesítés kezdete nem lehet korábbi a mai dátumnál.',
      salesStartMax:
        'Az értékesítés kezdete nem lehet későbbi dátum, mint a globális értékesítés vége {{ salesEndDate }}.',
      salesEndPast: 'Az értékesítés vége nem lehet korábbi a mai dátumnál.',
      salesEndMin: 'Az értékesítés vége nem lehet korábban, mint az értékesítés kezdete.',
      salesEndMax: 'Az értékesítés vége nem lehet később, mint a rendezvény vége.',
    },
    snackbarMsg: {
      saveSucceeded: 'Sikeres mentés.',
      saveFailed: 'Adatok mentése sikertelen.',
      preventMultipleEditEvents: 'Rendezvény szerkesztését már megnyitottad egy másik ablakban.',
      preventMultipleEditEventsByAnotherUser:
        '{{ nameHu }} felhasználó épp szerkeszti a rendezvényt.',
      coverImg: 'Rendezvényhez tartozó borítókép betöltése sikertelen.',
      gallery: 'Rendezvényhez tartozó galéria betöltése sikertelen.',
      ticketImg: 'Rendezvényhez tartozó jegykép betöltése sikertelen.',
      ticketTypeImgs: 'Rendezvényhez tartozó jegytípus képek betöltése sikertelen.',
      updateTicketTypeSalesEndDateFailed:
        'Jegyárak értékesítési dátumának automatikus frissítése nem sikerült. Ellenőrizd a "Jegy" szekció alatt.',
    },
  },
  eventList: {
    title: 'Rendezvények',
    breadcrumb: {
      dashboard: 'Vezérlőpult',
      event: 'Rendezvény',
      list: 'Lista',
    },
    newEvent: 'Új rendezvény',
    table: {
      tabs: {
        active: 'Aktív',
        archive: 'Archív',
      },
      toolbar: {
        search: 'Keresés...',
      },
      header: {
        id: 'ID',
        name: 'Név',
        location: 'Helyszín',
        eventStart: 'Kezdés',
        eventEnd: 'Vége',
        active: 'Státusz',
      },
      content: {
        statusInfo: {
          draft: 'Piszkozat',
          readyToSale: 'Értékesítésre kész',
          onSale: 'Értékesítés folyamatban',
          closedSale: 'Lezárult értékesítés',
        },
      },
      actions: {
        edit: 'Szerkesztés',
        delete: 'Törlés',
      },
      footer: {
        dense: 'Tömörítés',
        rowsPerPage: 'Oldalankénti sorok',
      },
      empty: 'Nincs adat',
    },
  },
  limitManagement: {
    title: 'Limitkezelés',
    breadcrumb: {
      dashboard: 'Vezérlőpult',
      event: 'Rendezvény',
      limit: 'Limit',
    },
    table: {
      toolbar: {
        search: 'Keresés...',
      },
      header: {
        status: 'Státusz',
        id: 'ID',
        name: 'Név',
        eventStart: 'Kezdés',
        salesEnd: 'Értékesítés vége',
      },
      content: {
        statusInfo: {
          draft: 'Piszkozat',
          readyToSale: 'Értékesítésre kész',
          onSale: 'Értékesítés folyamatban',
          closedSale: 'Lezárult értékesítés',
        },
        confirmInfo: {
          save: 'Mentés',
          required: 'Limit megadása kötelező.',
          soldLimit: 'A limit nem lehet kevesebb, mint az eladott jegyek száma.',
          cancel: 'Mégse',
        },
      },
      footer: {
        openAll: 'Összes kinyitása',
        closeAll: 'Összes becsukása',
        rowsPerPage: 'Oldalankénti sorok',
      },
      empty: 'Nincs adat',
      ticketTypeTable: {
        header: {
          offer: 'Árfejlődés',
          price: 'Jegyár',
          limit: 'Limit',
          sold: 'Eladott',
          onSale: 'Eladható',
          guestTicket: 'Tiszteletjegy',
        },
        content: {
          boxOffice: 'Pénztár',
          total: 'Összesen',
        },
      },
    },
    successMsg: {
      limitUpdateSucceeded: 'Sikeres mentés!',
    },
    errorMsg: {
      limitUpdateFailed: 'Limit módosítása sikertelen.',
    },
  },
  eventDelete: {
    confirmationDialog: {
      title: 'Törlés megerősítése',
      content: `Törölni szeretnéd a/az '{{ eventName }}' rendezvényt. Biztos vagy benne?`,
      confirm: 'Törlés',
      back: 'Mégsem',
    },
    errorMsg: {
      eventDeleteFailed: 'Kiválasztott rendezvény törlése sikertelen.',
      eventDeleteSucceeded: 'Sikeres törlés.',
    },
  },
  eventHistory: {
    title: 'Napló',
    breadcrumb: {
      dashboard: 'Vezérlőpult',
      events: 'Rendezvények',
      history: 'Napló',
    },
    table: {
      toolbar: {
        actionType: 'Művelet',
        all: 'Összes',
        search: 'Keresés...',
        from: 'Dátumtól',
        to: 'Dátumig',
      },
      header: {
        user: 'Felhasználó',
        action: 'Művelet',
        event: 'Rendezvény',
        created: 'Dátum',
        status: 'Státusz',
      },
      statusSuccess: 'Sikeres művelet',
      statusFailed: 'Sikertelen művelet',
      details: 'Részletek',
      footer: {
        dense: 'Tömörítés',
        rowsPerPage: 'Oldalankénti sorok',
      },
      empty: 'Nincs adat',
    },
    details: {
      user: 'Felhasználó:',
      event: 'Rendezvény:',
      modified: 'Módosítva:',
      boxOffice: 'Pénztár:',
      sections: {
        basicData: '- alapadatok',
        location: '- helyszín',
        ticket: '- jegy',
        pictures: '- képek',
        description: '- leírás',
        limit: '- limit',
      },
      info: {
        createEvent: 'Új rendezvény hozzáadása',
        editEvent: 'Rendezvény módosítása',
        deleteEvent: 'Rendezvény törlése',
        editOffer: 'Árfejlődés szerkesztése',
      },
      error: 'Valami hiba történt.',
    },
    actions: {
      createEvent: 'Rendezvény létrehozása',
      editEvent: 'Rendezvény szerkesztése',
      deleteEvent: 'Rendezvény törlése',
      editOffer: 'Árfejlődés szerkesztése',
    },
  },
  statistics: {
    sales: {
      report: {
        label: 'Riport',
        newReport: 'Új riport',
        form: {
          email: 'Email',
          schedule: 'Gyakoriság',
          daily: 'Napi',
          weekly: 'Heti',
          startTime: 'Kezdő dátum',
          sendingLanguage: 'Küldés nyelve',
          language: 'Nyelv',
          save: 'Mentés',
          edit: 'Szerkesztés',
          cancel: 'Mégse',
          saveSuccess: 'Sikeres mentés!',
          saveFailed: 'Riport mentése sikertelen.',
          deleteSuccess: 'Sikeres törlés.',
          deleteFailed: 'Riport törlése sikertelen.',
          errorMsg: {
            required: '{{ fieldNameHu }} megadása kötelező.',
            createReportFailed: 'Riport létrehozása sikertelen.',
            selectEvent: 'Rendezvény választása kötelező.',
            addEmail: 'Email cím megadása kötelező.',
            validEmail: 'Email cím formátuma nem megfelelő.',
            selectSchedule: 'Gyakoriság választása kötelező.',
            selectStartTime: 'Kezdő dátum megadása kötelező.',
            invalidDate: 'Érvénytelen dátum',
            minDate: 'Érvénytelen dátum *',
            minDateInfo: '* A kezdő dátum nem lehet korábbi a mai napnál.',
            eventHasReport:
              'Ha egy rendezvényhez már létrehoztál riportot, akkor a listában tudod szerkeszteni vagy új email címet hozzáadni.',
          },
          help: {
            info: 'Aktuális jegyeladásról riport küldése emailben. <l>Részletek</l>',
            title: 'Részletek',
            contentP1:
              'Az Új riport gombra kattintva napi vagy heti rendszerességgel küldhető jegyeladási riportokat állíthatunk be.',
            contentP2:
              'Az emailes riportot a megadott kezdő dátumot figyelembe véve, mindig ugyanabban az időpontban, automatikusan kapja meg a címzett, egészen a rendezvény lezárultáig.',
            contentP3:
              'A beállított riporthoz további címeket adhatunk, illetve szerkeszthető, törölhető vagy a címzett is leiratkozhat róla.',
            contentP4:
              'Az emailben jegytípusokra bontva jelenik meg az aktuális eladott jegyek száma.',
          },
        },
        listing: {
          title: 'Riportok',
          header: {
            reportId: 'Riport ID',
            eventId: 'Rendezvény ID',
            eventName: 'Rendezvény neve',
            startTime: 'Kezdő dátum',
            sendingLanguage: 'Küldés nyelve',
            language: 'Nyelv',
          },
          content: {
            schedule: {
              daily: 'Napi',
              weekly: 'Heti',
            },
            language: {
              hu: 'Magyar',
              en: 'Angol',
            },
            noData: 'Még nem hoztál létre riportot.',
          },
          actions: {
            edit: 'Szerkesztés',
            delete: 'Törlés',
            reportDeletionAllowed:
              "A következő riportot szeretnéd törölni: '{{ report }}'. Biztos vagy benne?",
            emailDeletionAllowed:
              "A következő emailt szeretnéd törölni: '{{ email }}'. Biztos vagy benne?",
          },
          empty: 'Nincs adat',
        },
        unsubscribe: {
          successMsg: 'Sikeresen leiratkoztál!',
          errorMsg: 'Valami hiba történt. Kérjük, próbálkozz újra később!',
        },
      },
    },
  },
  statisticsSales: {
    title: 'Eladási statisztika',
    breadcrumb: {
      dashboard: 'Vezérlőpult',
      statistics: 'Statisztika',
      sales: 'Eladás',
    },
    table: {
      tabs: {
        active: 'Aktív',
        archive: 'Archív',
      },
      toolbar: {
        search: 'Keresés...',
        period: {
          label: 'Időszak',
          all: 'Összes',
          currentDay: 'Mai nap',
          currentWeek: 'Aktuális hét',
          previousWeek: 'Előző hét',
          currentMonth: 'Aktuális hónap',
          previousMonth: 'Előző hónap',
          customized: 'Egyéni',
        },
        from: 'Dátumtól',
        to: 'Dátumig',
      },
      header: {
        status: 'Státusz',
        id: 'ID',
        name: 'Név',
        location: 'Helyszín',
        eventStart: 'Kezdés',
        salesEnd: 'Értékesítés vége',
      },
      content: {
        confirmInfo: {
          save: 'Mentés',
          required: 'Limit megadása kötelező.',
          soldLimit: 'A limit nem lehet kevesebb, mint az eladott jegyek száma.',
          cancel: 'Mégse',
        },
        details: 'Részletes statisztika',
        noData: 'Nincs a szűrésnek megfelelő adat.',
      },
      footer: {
        openAll: 'Összes kinyitása',
        closeAll: 'Összes becsukása',
        rowsPerPage: 'Oldalankénti sorok',
      },
      empty: 'Nincs adat',
      ticketTypeTable: {
        header: {
          offer: 'Árfejlődés',
          price: 'Jegyár',
          limit: 'Limit',
          sold: 'Eladott',
          revenue: 'Bevétel',
          onSale: 'Eladható',
          onSaleInfo:
            'Az összes eladható jegy, az Eladható darabszámot nem befolyásolja az időszak szűrő. ',
          guestTicket: 'Tiszteletjegy',
        },
        content: {
          boxOffice: 'Pénztár',
          total: 'Összesen',
        },
      },
    },
    details: {
      title: 'Részletes statisztika',
      sold: 'Eladott',
      onSale: 'Eladható',
      revenue: 'Jegybevétel',
      guestTicket: 'Tiszteletjegy',
      previousPeriod: 'Növekedés',
      summary: {
        ticketType: 'Jegytípus',
        offer: 'Jegyár',
        total: 'Összesen',
        online: 'Online',
        boxOffice: 'Pénztár',
        noData: 'Nincs adat',
      },
      pcs: 'db',
    },
    successMsg: {
      limitUpdateSucceeded: 'Sikeres mentés!',
    },
    errorMsg: {
      limitUpdateFailed: 'Limit módosítása sikertelen.',
    },
  },
  statisticsCustomized: {
    title: 'Egyéni statisztika',
    breadcrumb: {
      dashboard: 'Vezérlőpult',
      statistics: 'Statisztika',
      customizedStatistics: 'Egyéni statisztika',
      customizedStatisticsCategory: 'Kategóriák',
    },
    create: 'Új egyéni statisztika',
    help: {
      info: 'Jegytípusaidat az általad meghatározott kategóriák szerint rendezheted. <1>Részletek</>',
      summary:
        'Az egyéni statisztika összeállításához bármely rendezvényedből választhatsz jegytípusokat és azokat általad meghatározott kategóriák szerint csoportosíthatod. A jegytípusokhoz szorzó számokat is rendelhetsz. Az alábbi példában egy fesztivál napi bontásával szemléltetjük a funkciót, de bármilyen más egyéni igény alapján kategorizálhatóak a jegyek.',
      example: {
        title: 'Példa',
        content:
          'Az egyéni statisztikával összeállítható egy többnapos rendezvénynél a várható napi látogatók száma. Egy páros bérlet esetén pedig kétszeres szorzót adhatunk a jegytípusnak, hogy megkapjuk a jegytípusnak megfelelő látogatószámot. Amennyiben van olyan jegytípusod, ahol a látogató döntheti el, melyik napon lép be a jegyével, akkor 1-nél kisebb szorzót is megadhatsz. Például a két napos fesztiválodon van egy bármely napon felhasználható napijegy, akkor 50-50% esély van arra, hogy az első vagy a második napon lép be vele. Így 0.5-ös szorzóval megbecsülheted melyik nap látogatói számát gyarapítja az eladott jegymennyiség.',
      },
      close: 'Bezár',
    },
    form: {
      titleCreate: 'Új egyéni statisztika',
      titleEdit: 'Egyéni statisztika szerkesztése',
      name: 'Név',
      englishName: 'Név angolul',
    },
    category: {
      title: 'Egyéni statisztika - kategóriák',
      showGuests: 'Tiszteletjegy hozzáadása',
      create: 'Kategória',
      form: {
        titleCreate: 'Új kategória',
        titleEdit: 'Kategória szerkesztése',
        name: 'Név',
        englishName: 'Név angolul',
      },
      item: {
        title: 'Jegytípusok',
        create: 'Jegytípus',
        tooltip: {
          confirmInfo: {
            required: 'Szorzó megadása kötelező',
            save: 'Mentés',
            cancel: 'Mégse',
          },
          delete: 'Törlés',
        },
      },
      tooltip: {
        edit: 'Szerkesztés',
        delete: 'Törlés',
      },
    },
    eventList: {
      header: {
        id: 'ID',
        name: 'Név',
        eventStart: 'Kezdés',
      },
    },
    tooltip: {
      edit: 'Szerkesztés',
      delete: 'Törlés',
    },
    save: 'Mentés',
    cancel: 'Mégse',
  },
  marketingCoupon: {
    title: 'Kupon',
    titleStatistics: 'Statisztika',
    breadcrumb: {
      dashboard: 'Vezérlőpult',
      marketing: 'Marketing',
      coupon: 'Kupon',
      couponCode: 'Kuponkód',
      statistics: 'Statisztika',
    },
    newCoupon: 'Új kupon',
    table: {
      header: {
        id: 'ID',
        name: 'Név',
        type: 'Típus',
        couponCode: 'Kuponkód',
        redemption: 'Beváltás',
        validFrom: 'Kezdés',
        validUntil: 'Vége',
      },
      content: {
        couponType: {
          discount: 'Százalék',
          fixedPrice: 'Fix összeg',
        },
        tooltip: {
          edit: 'Szerkesztés',
          couponCodes: 'Kuponkódok',
          statistics: 'Statisztika',
          details: 'Részletek',
        },
      },
      noData: 'Nincs a szűrésnek megfelelő adat.',
    },
    couponAddForm: {
      steps: {
        type: 'Típus',
        data: 'Adatok',
        redemption: 'Beváltás',
      },
      btn: {
        next: 'Tovább',
        save: 'Mentés',
        back: 'Vissza',
        search: 'Keresés...',
      },
      errors: {
        type: 'Típus kiválasztása kötelező.',
        name: 'Kupon név megadása kötelező.',
        validFrom: 'A kezdés dátum megadása kötelező.',
        invalidDate: 'Érvénytelen dátum',
        validUntil:
          'A beváltási időszak vége nem lehet korábban, mint a beváltási időszak kezdete.',
        discountValue: 'Kedvezmény mértékének megadása kötelező.',
        discountMaxValue:
          'A kedvezmény értékének maximalizálásához adj meg egy 0-nál nagyobb számot.',
        minimumCartValue: 'A minimum kosárérték beállításához adj meg egy 0-nál nagyobb számot.',
        redemptionLimit: 'Limit megadáshoz adj meg egy 0-nál nagyobb számot.',
        cartValueLimit: 'A kosár értéke nem lehet kevesebb a kedvezmény értékénél.',
        valueGreaterThan100: 'A kedvezmény százalék értéke nem lehet 100-nál nagyobb.',
        couponRestrictions: 'Rendezvény választása kötelező.',
      },
      label: {
        couponInfo: 'Kupon információ',
        validEvents: 'Rendezvények',
        infoToAllEventsSelect:
          'A piszkozatokra, értékesítésre kész és folyamatban lévő rendezvényekre is érvényes lesz a kupon.',
        saveSuccess: 'Sikeres mentés!',
        saveFailed: 'Mentés sikertelen.',
        couponHelperText:
          'Az archivált kuponokat nem lehet szerkeszteni. A részletes informaciókat itt találod:',
      },
      CouponAddType: {
        fixedPrice: 'Fix összeg',
        fixedPriceLabel: 'A kosár értékére adható fix összegű kedvezmény.',
        discount: 'Százalék',
        discountLabel: 'A kosár értékére adható százalékos kedvezmény.',
        tooltip: {
          warn: 'A kupon típusa utólag nem módosítható.',
        },
      },
      CouponAddData: {
        type: 'Típus',
        name: 'Kupon név',
        nameLabel: 'Ez nem a kuponkód, csak a kedvezmény megnevezése, pl. Karácsony 2024',
        period: 'Időszak',
        periodLabel: 'A Vége dátum megadása nélkül a kupon(ok) határozatlan ideig beváltható(k).',
        validFrom: 'Kezdete*',
        validUntil: 'Vége',
        discount: 'Kedvezmény',
        discountRalue: 'Kedvezmény mértéke',
        discountRalueLabel: 'Csak az első beváltásig módosítható.',
        discountValue: 'Kedvezmény értéke',
        discountMaxValue: 'Kedvezmény max. értéke',
        minimumCartValue: 'Minimum kosárérték',
        redemptionLimit: 'Beváltás limit',
        redemptionLimitLabel:
          'Hányszor lehessen beváltani egy kuponkódot? A beváltási limit kikapcsolásával a később létrehozott kuponkódokat végtelenszer be lehet váltani.',
        authoriseRefund: 'Visszatérítés engedélyezése',
        authoriseRefundLabel:
          'Ez a kapcsoló az ügyfélszolgálatunk munkáját segíti, nincs hatással a rendelésekre.',
        isRestricted: 'Rendezvényre korlátozva',
        pcs: 'db',
      },
    },
    couponCode: {
      title: 'Kuponkódok',
      redemptionLimit: 'Beváltási limit',
      noneRedemptionLimit: 'nincs',
      creationType: {
        manually: 'Manuális',
        generate: 'Generálás',
        upload: 'Feltöltés',
      },
      archived: 'Archivált kupontörzshöz nem lehet új kuponkódot hozzáadni.',
      manuallyForm: {
        couponCode: 'Kuponkód',
        chars: '4-32 karakter',
        tooltip: {
          characters: 'Engedélyezett: {{ allowedChars }}<1 />Tiltott: {{ forbiddenChars }}',
          save: 'Mentés',
        },
        errorMsg: {
          required: '{{ fieldNameHu }} megadása kötelező.',
          forbiddenChars: '\' / " < > # % { } |  ^ ~ [ ] ` nem engedélyezett.',
          minLength: 'A kuponkódnak minimum 4 karakternek kell lennie.',
          maxLength: 'A kuponkód maximum 32 karakter lehet.',
        },
        snackbar: {
          saveSucceeded: 'Sikeres mentés!',
          saveFailed: 'Mentés sikertelen.',
          duplicateCode: 'Ez a kuponkód már aktív egy Kuponban.',
        },
      },
      generateForm: {
        quantity: 'Darabszám *',
        codeLength: 'Kód hossza *',
        prefix: 'Prefix',
        postfix: 'Postfix',
        pattern: 'Minta *',
        charset: {
          label: 'Karakterkészlet *',
          numeric: 'Számok',
          alphabetic: 'Betűk',
          alphanumeric: 'Alfanumerikus',
        },
        chars: 'max. 8 karakter',
        preview: 'Előnézet',
        generate: 'Generálás',
        errorMsg: {
          required: '{{ fieldNameHu }} megadása kötelező.',
          forbiddenChars: '\' / " < > # % { } |  ^ ~ [ ] ` nem engedélyezett.',
          prefixPostfixMaxLength: 'Nem lehet 8 karakternél hosszabb.',
        },
        dialog: {
          title: 'Figyelmeztetés',
          content: 'Több mint 10 000 kuponkódot szeretnél generálni. Biztos vagy benne?',
          cancel: 'Mégse',
        },
        snackbar: {
          generateSucceeded: 'Generálás folyamatban.',
          generateCompleted: 'Generálás kész!',
          generateFailed: 'Sikertelen generálás.',
        },
      },
      list: {
        actionType: {
          label: 'Művelet',
          activeSelected: 'Kijelöltek aktiválása',
          deactivatedSelected: 'Kijelöltek deaktiválása',
          downloadSelected: 'Kijelöltek letöltése',
          activeAll: 'Összes aktiválása',
          deactivatedAll: 'Összes deaktiválása',
          downloadAll: 'Összes letöltése',
        },
        action: {
          label: {
            chooseAction: 'Válassz műveletet',
            activate: 'Aktiválás ({{ summary }})',
            deactivate: 'Deaktiválás ({{ summary }})',
            download: 'Letöltés ({{ summary }})',
          },
        },
        table: {
          tabs: {
            active: 'Aktív',
            deactivated: 'Deaktivált',
          },
          header: {
            code: 'Kuponkód',
            redemptionCount: 'Beváltás',
          },
        },
        fileName: 'Tixa_kupon',
      },
    },
    statistics: {
      tile: {
        title: {
          ticket: 'Jegy',
          redemption: 'Beváltás',
          ticketRevenue: 'Jegybevétel',
          discount: 'Kedvezmény',
          growth: 'Növekedés',
        },
        tooltip: {
          ticket: 'kuponnal vásárolt jegyek',
          redemption: 'kuponbeváltások száma',
          ticketRevenue: 'kuponnal vásárolt jegyekből származó bevétel',
          discount: 'kuponbeváltásra adott kedvezmények összege',
          growth: 'aktuális kuponbeváltások az előző időszakhoz képest',
        },
      },
      graph: {
        series: {
          ticket: 'Jegy',
          redemption: 'Beváltás',
        },
      },
      list: {
        tabs: {
          detailed: 'Részletek',
          summary: 'Összesítés',
        },
        heading: {
          id: 'ID',
          name: 'Név',
          location: 'Helyszín',
          eventStart: 'Kezdés',
          purchasedTicketCount: 'Jegy',
          redemptionCount: 'Beváltás',
        },
        detailed: {
          ticketType: 'Jegytípus',
          originalPrice: 'Eredeti ár',
          quantity: 'Darab',
          discount: 'Kedvezmény',
          ticketPrice: 'Csökkentett ár',
          grossRevenue: 'Jegybevétel',
        },
        summary: {
          total: 'Összesen',
          quantity: 'Darab',
          grossRevenue: 'Jegybevétel',
        },
      },
    },
  },
  ntak: {
    attractionRegistration: {
      title: 'Regisztráció',
      breadcrumb: {
        dashboard: 'Vezérlőpult',
        attraction: 'Attrakció',
        registration: 'Regisztráció',
      },
      data: {
        header: 'Regisztráció',
        title: 'Alap adatok',
        save: 'Mentés',
        taxNumber: 'Adószám',
        taxNumberHelperText: 'Minta: 11223344122',
        registrationNumber: 'Regisztrációs szám',
        registrationNumberHelperText: 'Minta: MK22001361',
        saveSuccess: 'Sikeres mentés!',
        saveFailed: 'Mentés sikertelen',
      },
      generateCert: {
        title: 'Tanúsítvány generálás',
        generate: 'Generálás',
        download: 'Letöltés',
        certCreated: 'Tanúsítvány generálása sikeres.',
        certCreatedAt: 'Generálva: {{ date }}',
      },
      uploadCert: {
        title: 'Tanúsítvány feltöltés',
        upload: 'Feltöltés',
        delete: 'Törlés',
        chooseFile: 'Fájl kiválasztása...',
        certUploadedAt: 'Feltöltve: {{ date }}',
        fileNotCer: 'A kiválasztott fájl nem .cer kiterjesztésű.',
      },
      guide: {
        title: 'Segédlet',
        linkText: 'Részletes útmutató a regisztrációs folyamathoz',
        emailText:
          'A regisztrációval és az adatok megadásával kapcsolatos kérdéseidre az útmutatóban találsz választ. Ha mégsem találnád a megfelelő választ, az alábbi e-mail címen tudnak segítséget nyújtani az NTAK kollégái.',
      },
      content: {
        header:
          'Az adatszolgáltatáshoz az alábbi lépéseket szükséges megtenned az <o>NTAK</o> oldalán és itt a <s>TIXA</s> partner adminban:',
        firstParagraph: '<l>Regisztrálj</l> az NTAK honlapján az Ügyfélkapus fiókod segítségével.',
        secondParagraph: 'Hozd létre a felhasználói fiókod.',
        thirdParagraph:
          'Rögzítsd magad <strong>szolgáltató</strong>ként az + ÚJ SZOLGÁLTATÓ gombra kattintva.',
        fourthParagraph:
          'Regisztráld a <o>szolgáltatási hely</o>e(i)det az + Új szolgáltatási hely felvétele link segítségével, mely a táblázatban a Kinyit feliratra kattintva érhető el.',
        fifthParagraph:
          'Kattints az Attrakció oszlop alján található nyílra, ezáltal átkerülsz az <o>Attrakció modul</o>ba.',
        sixthParagraph:
          'A Szolgáltatási helyek menüpontban kattints a szolgáltatási hely melletti Szerkesztésre és egészítsd ki az adataidat.',
        seventhParagraph:
          'Kattints a <o>Szoftverek, tanúsítványok</o> gombra, majd az Új szoftver hozzáadása gombra és válaszd ki a TIXÁ-t a listából.',
        eighthParagraph:
          'Lépj vissza a Szolgáltatási helyek menüpontba és másold ki az <o>NTAK regisztrációs szám</o>ot, amelyet a szolgáltatási hely neve mellett találsz.',
        ninthParagraph:
          'Az <o>Alapadatok doboz</o>ban add meg az adószámodat (kötőjelek nélkül) és az NTAK regisztrációs számot, majd nyomj a Mentés gombra.',
        tenthParagraph:
          'A <o>Tanúsítvány generálás</o> dobozban generálj egy tanúsítványt, majd töltsd le.',
        eleventhParagraph:
          'Az NTAK Attrakció moduljában kattints a szolgáltatási helyedhez tartozó <o>Szoftverek, tanúsítványok</o> gombra, majd a <o>Tanúsítványkezelés</o> gombra.',
        twelfthParagraph:
          'Töltsd fel az NTAK oldalára a TIXA tanúsítványt. Ha a feltöltés Státusza percekig nem változik, akkor nyugodtan frissítsd az oldalt. Sikeres feltöltés esetén a státusz ‘Tanúsítvány kész’.',
        thirteenthParagraph:
          'Az így kapott NTAK tanúsítványt töltsd le, majd a TIXA partner adminban a <o>Tanúsítvány feltöltése</o> dobozba töltsd vissza.',
      },
      errorMsg: {
        taxNumber: 'Az adószám 11 karakterből és csak számokból áll.',
        registrationNumber: 'A regisztrációs szám 2 betűből és 8 számból áll.',
        verified: 'Fiókod adatszolgáltatásra kész.',
      },
    },
    attractionList: {
      title: 'Attrakciók',
      breadcrumb: {
        dashboard: 'Dashboard',
        attraction: 'Attrakció',
        list: 'Napló',
      },
      table: {
        header: {
          orderId: 'Rendelés ID',
          status: 'Státusz',
          processingId: 'Feldolgozási azonosító',
          orderType: 'Rendelés típusa',
          creationTime: 'Létrehozás ideje',
          submissionTime: 'Beküldés ideje',
          checkResponseTime: 'Ellenőrzés válasz ideje',
        },
      },
    },
    boxoffice: {
      title: 'Rendezvények',
      breadcrumb: {
        dashboard: 'Vezérlőpult',
        boxOffice: 'Pénztár',
        events: 'Rendezvények',
        settings: 'Beállítások',
        onSiteTicketing: 'Helyszíni jegyek',
      },
      settings: {
        title: 'Beállítások',
        payment: {
          paymentMethod: 'Fizetési módok',
          cashHUF: 'Készpénz HUF',
          cashEUR: 'Készpénz EUR',
          szepCard: 'SZÉP kártya',
          creditCard: 'Bankkártya',
          transfer: 'Átutalás',
          other: 'Egyéb',
          voucher: 'Voucher',
          roomLoan: 'Szobahitel',
        },
      },
      events: {
        tooltip: {
          editTickets: 'Helyszíni jegyek szerkesztése',
          sales: 'Eladás',
        },
      },
      offlineTicket: {
        title: 'Helyszíni jegyek',
        newOnsiteTicket: 'Új helyszíni jegy',
        editOnsiteTicket: 'Helyszíni jegy szerkesztése',
        name: 'Név',
        quantity: 'Darabszám',
        price: 'Ár',
        ticketType: 'Jegytípus név',
        uniqueTicketType: 'Egyedi jegytípus',
        existingTicketType: 'Meglévő jegytípusok',
        actions: {
          ticketDeletionAllowed:
            'A következő jegyet szeretnéd törölni: {{ ticketName }}. Biztos vagy benne?',
          ticketTypeNameHelper:
            'Ha nem választasz meglévő jegytípust, akkor az itt megadott név lesz a jegy neve.',
        },
        confirmationDialog: {
          title: 'Darabszám nulla',
          content: `Nem adtál meg darabszámot, így előfordulhat, hogy több jegyet rögzítesz, mint amennyi helyszíni jegyed van. Biztos így szeretnéd?`,
          confirm: 'Mentés',
          back: 'Mégsem',
        },
      },
      sales: {
        salesHeader: 'Eladás',
        type: 'Típus',
        boxOffice: 'Pénztár',
        offlineTicket: 'Helyszíni jegy',
        ticketType: 'Jegytípus',
        price: 'Ár',
        quantity: 'Darabszám',
        invoice: 'Számla',
        zipCode: 'Irányítószám',
        inland: 'Belföld',
        abroad: 'Külföld',
        country: 'Ország',
        paymentMethod: 'Fizetési mód',
        usedImmediately: 'Azonnal felhasznált jegy(ek)',
        accomodationInfo: 'A vásárló lakhelyére vonatkozó adat',
        btn: {
          boxOffice: 'Kiállít',
          offlineTickets: 'Beküld',
        },
        soldOutDialog: {
          title: 'Limittúllépés',
          content:
            'A kiválasztott jegyek tartalmaznak már kiárusított jegyeket. Kérlek ellenőrizd!',
          btn: 'OK',
        },
        snackbar: {
          successfulSubmit: 'Sikeres beküldés',
          failedSubmit: 'Sikertelen beküldés',
          soldOutTickets: 'Túllépted a limitet.',
        },
        errorMsg: {
          required: '{{ fieldNameHu }} megadása kötelező.',
          zipCodeMin: 'Az irányítószám nem lehet kisebb 1000-nél.',
        },
      },
    },
  },
  settlement: {
    title: 'Elszámolás',
    breadcrumb: {
      dashboard: 'Vezérlőpult',
      finance: 'Pénzügy',
      settlement: 'Elszámolás',
      detailedSettlement: 'Részletes elszámolás',
    },
    help: {
      comissionContract: {
        info: 'Bizományosi szerződésed van, így a rendezvényed megvalósulása után várjuk tőled a teljes jegybevételről (bruttó jegyár bevétel oszlop) a számlát, majd a beérkezése után kiállítjuk a jutalékszámlát és a két számla különbözetét utaljuk. <l>Részletes útmutató</l>',
        details: {
          billingInformation: {
            title: 'Számlázási tudnivalók',
            content:
              'A rendezvényed megvalósulása után várjuk tőled a teljes jegybevételről (bruttó jegyár bevétel oszlop) a számlát, majd a beérkezése után kiállítjuk a jutalékszámlát és a két számla különbözetét utaljuk.',
          },
          billingAddress: {
            title: 'Számlázási cím',
            content:
              'TIXA Hungary Kft. <1/>5600 Békéscsaba, Dobozi út 58. <1/>Adószám: 24813064-2-04',
          },
          postalAddress: {
            title: 'Levelezési cím (ide kérjük a számlát)',
            content: 'TIXA Hungary Kft. <1/>1091 Budapest, Üllői út 25.',
          },
          invoiceDetails: {
            title: 'Számla kiállítása',
            list: '<1><2><3/></2><4>jegyértékesítés legyen a jogcím</4></1><1><2><3/></2><4>megjegyzésben szerepeljen mindenképpen a rendezvény azonosítója, lehetőség szerint a rendezvény neve is</4></1><1><2><3/></2><4>a táblázatban a bruttó bevételt látod</4></1><1><2><3/></2><4>átutalásos számlát várunk tőled</4></1><1><2><3/></2><4>számla kelte: az a nap, amikor kiállítod a számlát</4></1><1><2><3/></2><4>számla fizetési határideje: 8 nap</4></1><1><2><3/></2><4>számla teljesítési ideje: a számlán szereplő rendezvények közül az utoljára megvalósult rendezvény napja legyen</4></1><1><2><3/></2><4>az adószámnak szerepelni kell a számlán</4></1><1><2><3/></2><4>a számlán szerepeljen a számlaadó cég bankszámlaszáma</4></1>',
            content:
              'Számlát attól a cégtől fogadunk be, amin keresztül ment az értékesítés, és akivel szerződésünk van. <1/>Számlát kiegyenlíteni viszont bárhonnan lehet, csak jelezd a közleményben a számla sorszámát. <1/>Csak az eredeti számla beérkezése után tudunk utalni, szkennelt számlát nem kérünk. Ellenben az e-számlát szívesen befogadjuk. A fenti levelezési címünkre küldj papír alapú számlát, emailben pedig a penzugy@tixa.hu-ra.',
          },
          vatRates: {
            title: 'Számlabontás',
            content:
              'Az értékesítés elindítása előtt jelezd, ha az értékesítéskor kiállításra kerülő számlákon bizonyos tételek más áfával jelenjenek meg. (Például: ha a jegyár helyben készített meleg ételt is tartalmaz.)',
          },
          specialServices: {
            title: 'Számlák külön szolgáltatásokról',
            content:
              'Ha karszalagot, papír jegyet, beléptetést rendeltél, azt külön számlán fogjuk küldeni emailben.',
          },
        },
      },
      ownContract: {
        info: 'Saját fiókos szerződésed van, így a TIXA fog kiállítani számlát a részedre (TIXA költsége oszlop). <l>Részletes útmutató</l>',
        details: {
          vatRates: {
            title: 'Számlabontás',
            content:
              'Az értékesítés elindítása előtt jelezd, ha az értékesítéskor kiállításra kerülő számlákon bizonyos tételek más áfával jelenjenek meg. (Például: ha a jegyár helyben készített meleg ételt is tartalmaz.)',
          },
          specialServices: {
            title: 'Számlák külön szolgáltatásokról',
            content:
              'Ha karszalagot, papír jegyet, beléptetést rendeltél, azt külön számlán fogjuk küldeni emailben.',
          },
          downloadReport: {
            title: 'Simplepay riport letöltése',
            content:
              'A SimplePay által kiutalásra került összegek részleteit egy riportban le tudod tölteni a <l>SimplePay adminból</l> és ezt tudod továbbítani a könyvelőd részére. Az adminba belépve a Pénzügyek - Utalások - Kimenő utalások menüpontokra kattintva (dátum szerinti szűréssel is akár) látható minden utalásnak a riportja, amit le lehet tölteni. Eszerint tudja a könyvelő párosítani a kiállított számlákat és a SimplePay-től beérkezett összeget. Ugyanitt jelennek meg balance report néven azok a tranzakciók, amelyek a visszatérítésről szólnak.',
          },
        },
      },
      close: 'Bezár',
    },
    detailedSettlement: {
      title: 'Részletes elszámolás',
      tabs: {
        online: 'Online',
        coupon: 'Kupon',
        boxOffice: 'Pénztár',
        others: 'Egyéb',
        all: 'Összes',
      },
      overview: {
        tixaCost: 'Tixa költsége',
        netIncome: 'Nettó bevétel',
        vat: 'ÁFA ({{ vat }}%)',
        grossIncome: 'Bruttó bevétel',
        tooltip: {
          tixaExpense:
            'Az összes kategória Tixára eső része (kerekítve), lásd zöld színnel jelölt tételek.',
          grossRevenue: 'Az összes kategória partnerre eső része, lásd kék színnel jelölt tételek.',
        },
      },
      online: {
        title: 'Online',
        ticketType: 'Jegytípus',
        price: 'Ár',
        quantity: 'Darab',
        grossIncome: 'Jegybevétel',
        commission: 'Jutalék ({{ commission }}%)',
        grossIncomeAfterCommission: 'Bevétel',
        coupon: 'Kupon',
        all: 'Összesen',
        tooltip: {
          gross: 'Bruttó',
        },
      },
      offline: {
        title: 'Pénztár',
      },
      handlingFee: {
        title: 'Kezelési költség',
        grossHFIncome: 'Kezelési ktg',
        commission: 'Jutalék ({{ commission }}%)',
        grossIncome: 'Bevétel ({{ grossIncome }}%)',
        tooltip: {
          gross: 'Bruttó',
        },
      },
      transferFee: {
        title: 'Szállítási díj',
        grossTransferIncome: 'Bruttó száll. díj bevétel',
        commission: 'Jutalék ({{ commission }}%)',
        grossIncome: 'Bruttó bevétel ({{ grossIncome }}%)',
      },
      refund: {
        title: 'Visszatérítés',
        grossRefund: 'Összeg',
        commission: 'Jutalék ({{ commission }}%)',
        tooltip: {
          gross: 'Bruttó',
          commission: 'A visszatérített jegyek jutaléka.',
          grossRefund: ' A visszatérített jegyek összege nem szerepel az online jegybevételben.',
        },
      },
      guestTicket: {
        title: 'Tiszteletjegy',
        quantity: 'Darab',
        price: 'Egységár',
        sum: 'Összesen',
      },
      coupon: {
        title: 'Kupon',
        ticketType: 'Jegytípus',
        price: 'Ár',
        quantity: 'Darab',
        grossIncome: 'Jegybevétel',
        commission: 'Jutalék ({{ commission }}%)',
        grossIncomeAfterCommission: 'Bevétel',
        coupon: 'Kupon',
        all: 'Összesen',
        tooltip: {
          gross: 'Bruttó',
        },
      },
    },
    table: {
      toolbar: {
        settlementStatus: {
          label: 'Fizetési státusz',
          all: 'Összes',
          paid: 'Fizetve',
          pending: 'Elszámolásra vár',
        },
      },
      header: {
        status: 'Státusz',
        id: 'ID',
        name: 'Név',
        eventStart: 'Kezdés',
        location: 'Helyszín',
        grossRevenue: 'Online jegybevétel',
        grossRevenueInfo: 'Bruttó',
        commission: 'Tixa költsége',
        commissionInfo: 'Bruttó',
        payment: 'Fizetés',
      },
      content: {
        statusInfo: {
          onSale: 'Értékesítés folyamatban',
          closedSale: 'Lezárult értékesítés',
        },
        details: 'Részletes elszámolás',
        total: 'Összesen',
        noData: 'Nincs a szűrésnek megfelelő adat.',
      },
      footer: {
        rowsPerPage: 'Oldalankénti sorok',
        dense: 'Tömörítés',
      },
    },
  },
  guestInvite: {
    title: 'Meghívás',
    breadcrumb: {
      dashboard: 'Vezérlőpult',
      guests: 'Tiszteletjegy',
      invite: 'Meghívás',
      inviteStatistics: 'Meghívás statisztika',
    },
    page: {
      btn: {
        invite: 'Meghívás',
      },
    },
    tooltip: {
      invite: 'Meghívás küldése',
      statistics: 'Statisztika',
    },
    label: {
      invited: 'Meghívott',
    },
    form: {
      saveSuccess: 'Sikeres meghívás!',
      saveFailed: 'Meghívás sikertelen',
      label: {
        category: 'Kategória',
        ticketType: 'Jegytípus',
        firstName: 'Keresztnév',
        lastName: 'Vezetéknév',
        company: 'Cég/szervezet',
        email: 'Email cím *',
        language: 'Nyelv',
        comment: 'Megjegyzés',
        individual: 'Egyéni',
        tickets: 'Jegyek',
        guestData: 'Vendég adatai',
      },
      helperText: {
        name: 'Jegyen és emailben szereplő név. (nem kötelező)',
        company: 'Jegyen szereplő cég/szervezet. (nem kötelező)',
        comment: 'Belső információ, címzettnek nem jelenik meg. (nem kötelező)',
      },
      btn: {
        invite: 'Meghívás',
        download: 'Letöltés',
      },
      error: {
        required: '{{ fieldNameHu }} megadása kötelező',
        ticketTypeRequired: 'Legalább egy jegytípus megadása kötelező',
      },
    },
    download: {
      fileName: 'Tixa_tiszteletjegy',
    },
    statistics: {
      title: 'Meghívás statisztika',
      label: {
        quantity: 'Darabszám',
        category: 'Kategória',
        ticketType: 'Jegytípus',
      },
      text: {
        total: 'Összesen',
      },
    },
  },
  guestlist: {
    title: 'Vendégek',
    breadcrumb: {
      dashboard: 'Vezérlőpult',
      guests: 'Tiszteletjegy',
      guestlist: 'Vendégek',
    },
    table: {
      header: {
        id: 'ID',
        lastName: 'Vezetéknév',
        firstName: 'Keresztnév',
        email: 'Email',
        event: 'Rendezvény',
        user: 'Felhasználó',
        date: 'Dátum',
      },
      details: {
        ticketType: 'Jegytípus',
        quantity: 'Darabszám',
      },
    },
  },
  guestDetails: {
    title: 'Vendég részletek',
    breadcrumb: {
      dashboard: 'Vezérlőpult',
      guests: 'Tiszteletjegy',
      guestDetails: 'Vendég részletek',
    },
    guestData: {
      title: 'Vendég',
      firstName: 'Keresztnév',
      lastName: 'Vezetéknév',
      companyName: 'Cég/szervezet',
      email: {
        label: 'Email',
        invalidEmail: 'Email cím formátuma nem megfelelő.',
      },
      language: {
        title: 'Email nyelve',
        hu: 'magyar',
        en: 'angol',
      },
      modifyEmailInfo:
        'Az email cím módosítása nem jár automatikus újraküldéssel. Ha elsőre rossz címre küldted a tiszteletjegyet, érdemes újragenerálni a vonalkódot, majd ezután elküldeni az új címre.',
    },
    invitationData: {
      title: 'Meghívás',
      invitationId: 'Meghívás ID',
      eventId: 'Rendezvény ID',
      event: 'Rendezvény',
      eventDate: 'Rendezvény dátuma',
      user: 'Felhasználó',
      dateOfSending: 'Küldés időpontja',
    },
    guestTickets: {
      title: 'Jegyek',
      table: {
        header: {
          serialNumber: 'Sorszám',
          ticketId: 'Jegy ID',
          ticketType: 'Jegytípus',
          category: 'Kategória',
          barcode: 'Vonalkód',
          seat: 'Szektor/sor/szék',
          sent: 'Elküldve',
        },
        footer: {
          showAll: 'Összes megjelenítése',
        },
      },
      actionType: {
        label: 'Művelet',
        regenerate: 'Újragenerálás',
        resend: 'Küldés',
        delete: 'Törlés',
        tooltip: 'Válassz legalább egy jegyet',
      },
      snackbar: {
        regenerate: 'Vonalkód(ok) sikeresen újragenerálva.',
        resend: 'Jegy(ek) sikeresen elküldve.',
        delete: 'Jegy(ek) sikeresen törölve.',
      },
      actionInfo: {
        regenerate:
          'Régi vonalkód törlése, új létrehozása. Az új vonalkóddal ellátott jegyeket nem kapja meg automatikusan a vendég, szükség esetén a ‘Küldés’ művelettel lehet elküldeni.',
        delete: 'A jegy(ek) törlése nem visszavonható.',
      },
    },
    guestHistory: {
      title: 'Napló',
      table: {
        header: {
          user: 'Felhasználó',
          action: 'Művelet',
          date: 'Dátum',
          details: 'Részletek',
        },
      },
      actionType: {
        label: 'Művelet',
        all: 'Összes',
        modifyEmail: 'Email módosítása',
        regenerate: 'Újragenerálás',
        resend: 'Küldés',
        delete: 'Törlés',
      },
    },
    guestComment: {
      title: 'Megjegyzés',
    },
  },
  guestSettings: {
    title: 'Beállítások',
    breadcrumb: {
      dashboard: 'Vezérlőpult',
      guests: 'Tiszteletjegy',
      guestSettings: 'Beállítások',
    },
    type: {
      user: 'Felhasználói',
      organizer: 'Szervezői',
    },
    info: {
      user: 'A felhasználói szintű beállítás felülírja a szervezői szintű beállításokat az általad küldött jegyeknél.',
      organizer: 'Minden tiszteletjegyküldésre jogosult felhasználónál ez lesz az alapbeállítás.',
    },
    replyTo: {
      title: 'Reply to beállítások',
      info: 'Az itt beállított email címre fog érkezni a vendég meghívóra küldött válasza.',
      form: {
        email: 'Email',
        save: 'Mentés',
      },
      confirmation: {
        titleSucceeded: 'A reply-to email sikeresen megerősítve.',
        titleFailed:
          'A reply-to email megerősítése nem sikerült. Próbáld újra vagy küldd újra a megerősítő emailt.',
        button: 'Vissza a beállításokhoz',
        info: 'A módosítás véglegesítéséhez kattints az email címedre küldött levélben található linkre. Ha nem találod <1>küldd újra!</1>',
      },
    },
    email: {
      title: 'Egyedi email tartalom',
      content: {
        english: 'Angol tartalom',
        hungarian: 'Magyar tartalom',
        toolbar: {
          dynamicContent: 'Dinamikus tartalom',
          reset: 'Eredeti szöveg visszaállítása',
          copy: 'Másol',
          copied: 'Másolva',
        },
        action: {
          cancel: 'Mégse',
          preview: 'Előnézet',
          save: 'Mentés',
        },
      },
    },
  },
  guestLimitManagement: {
    title: 'Limitkezelés',
    breadcrumb: {
      dashboard: 'Vezérlőpult',
      guests: 'Tiszteletjegy',
      limitManagement: 'Limitkezelés',
    },
  },
  seatMap: {
    dialog: {
      title: 'Hiba!',
      btn: 'ok',
    },
    seatMapInstance: {
      addBtn: 'Hozzáadás',
      helpers: {
        inactive:
          'Az eredetinél sötétebb kontrasztú szín eladásra inaktív, de tiszteletjegyként küldhető helyet jelöl.',
      },
    },
    guestInvite: {
      openSeatMapBtn: 'Helyválasztás',
      selectedSeatsTitle: 'Kiválasztott helyek',
      list: {
        header: {
          id: 'Id',
          category: 'Kategória',
          quantity: 'Darabszám',
        },
        detailedHeader: {
          id: 'Id',
          sector: 'Szektor',
          row: 'Sor',
          seat: 'Hely',
        },
        ticketDeletionAllowed:
          'A következő jegyet szeretnéd törölni: {{ ticketName }}. Biztos vagy benne?',
        ticketLimitWarn: 'Maximum {{ maxTickets }} jegyet lehet egyszerre kiválasztani.',
      },
    },
  },
  personalData: {
    isActive: 'Aktív',
    isInactive: 'Inaktív',
    header: 'Személyes adatok',
    firstName: 'Keresztnév *',
    lastName: 'Vezetéknév *',
    email: 'Email cím *',
    phoneNumber: 'Telefonszám *',
    submit: 'Felhasználó módosítása',
    errorMsg: {
      required: '{{ fieldNameHu }} megadása kötelező.',
      firstNameLength: 'A keresztnévnek minimum 3 karakternek kell lennie.',
      lastNameLength: 'A vezetéknévnek minimum 3 karakternek kell lennie.',
      validEmail: 'Email cím formátuma nem megfelelő.',
      validPhoneNumber: 'Telefonszám formátuma nem megfelelő.',
    },
  },
  companyData: {
    header: 'Szervezői fiók',
    helperText:
      'A fiókod adatait csak a kapcsolattartód tudja módosítani. Az alábbi űrlap kitöltésével elküldheted a módosítási kérelmeidet.',
    companyDataHeader: 'Cégadatok',
    accountName: 'Szervezői fiók neve *',
    link: 'Link *',
    accountNameInfo:
      'Ezen a néven jön létre a Tixa oldalán egy szervezői aloldal a rendezvényeiddel.',
    country: 'Ország',
    countryHu: 'Magyarország',
    taxNumber: 'Adószám {{ required }}',
    headquarters: 'Székhely',
    companyName: 'Cégnév *',
    postalCode: 'Irányítószám {{ required }}',
    city: 'Város {{ required }}',
    addressDetails: 'Cím {{ required }}',
    isDifferentMailingAddress: 'Levelezési cím eltér',
    mailingAddress: 'Levelezési cím',
    moreInformation: 'További adatok',
    representativeName: 'Képviselő neve *',
    vatStatus: 'ÁFA {{ required }}',
    vatStatusOptions: {
      vatExemptStatus: 'Alanyi adómentes (AAM)',
      exemptFromVat: 'Tárgyi adómentes (TAM)',
      0: '0%',
      5: '5%',
      18: '18%',
      27: '27%',
      other: 'Egyéb - jelezd a kapcsolattartódnak',
    },
    selectVat: 'Válassz ÁFÁ-t...',
    bankAccountNumber: 'Számlaszám {{ required }}',
    registeredNumber: 'Cégjegyzékszám/Nyilvántartási szám',
    contractForms: {
      header: 'Szerződési forma',
      comission: 'Bizományosi',
      comissionInfo:
        'A Tixa értékesít, számláz és hozzánk folyik be a bevétel, az esemény végén elszámolunk egymással. <1>További részletek...</>',
      own: 'Saját',
      ownInfo:
        'A te nevedben történik az értékesítés, a számlázás és hozzád folyik be a bevétel. <1>További részletek...</>',
      unknown: 'Nem tudom',
    },
    registerComment: 'Megjegyzés',
    create: 'Cégadatok mentése',
    modify: 'Cégadatok módosítása',
    send: 'Küldés',
    dialog: {
      title: 'Sikeres mentés',
      content:
        'Szervezői fiókodat sikeresen létrehoztuk. Hamarosan felvesszük veled a kapcsolatot!',
      btn: 'Ok',
    },
    errorMsg: {
      required: '{{ fieldNameHu }} megadása kötelező.',
      taxNumberFormat: 'Formátum nem megfelelő, ld: 11111111-1-11',
      postalCodeFormat: 'Az irányítószámnak 4 karakterből kell állnia.',
      accountNumberFormat: 'Formátum nem megfelelő, ld: 11111111-11111111-(11111111)',
      uniqueLink: 'A linknek egyedinek kell lennie.',
    },
  },
  accountProfile: {
    title: 'Profil',
    breadcrumb: {
      dashboard: 'Vezérlőpult',
      user: 'Fiók',
    },
    successMsg: {
      successfulUpdate: 'Személyes adatok sikeresen frissítve.',
    },
    errorMsg: {
      failedUpdate: 'Személyes adatok firssítése sikertelen.',
    },
  },
  accountCompanyData: {
    title: 'Cégadatok',
    breadcrumb: {
      dashboard: 'Vezérlőpult',
      account: 'Fiók',
      companyData: 'Cégadatok',
    },
    organizerDataHeader: 'Szervező adatai',
    accountName: 'Szervezői fiók neve',
    link: 'Link',
    companyDataHeader: 'Cégadatok',
    companyName: 'Cégnév',
    representativeName: 'Képviselő neve',
    taxNumber: 'Adószám',
    bankAccountNumber: 'Számlaszám',
    vatStatus: 'ÁFA',
    vatStatusOptions: {
      AAM: 'Alanyi adómentes (AAM)',
      TAM: 'Tárgyi adómentes (TAM)',
      0: '0%',
      5: '5%',
      18: '18%',
      27: '27%',
    },
    registeredNumber: 'Cégjegyzékszám',
    country: 'Ország',
    address: 'Cím',
    mailingAddress: 'Levelezési cím',
    moreInformation: 'További adatok',
    contractForm: 'Szerződési forma',
    contractForms: {
      comissionContract: 'Bizományosi',
      ownContract: 'Saját',
      unknown: 'Nem tudom',
    },
    registerComment: 'Megjegyzés',
    successMsg: {
      successfulRequest: 'Kérés sikeresen elküldve!',
    },
    errorMsg: {
      createFailed: 'Szervező létrehozása sikertelen.',
      failedRequest: 'Kérés küldése sikertelen.',
    },
  },
  userInvite: {
    title: 'Felhasználó meghívása',
    breadcrumb: {
      dashboard: 'Vezérlőpult',
      users: 'Felhasználók',
      invite: 'Meghívás',
    },
    form: {
      title: 'Jogosultságok módosítása',
      selectOwnRole: {
        myRoles: 'Saját szerepkörök',
        selectedUserRoles: '{{ lastName }} {{ firstNameHu }} szerepkörei',
        myRolesPlaceholder: 'Válassz a szerepkörökből...',
        selectedUserRolesPlaceholder: 'Válassz a felhasználó szerepköreiből...',
      },
      email: 'Email',
      role: 'Szerepkör',
      customizedRoleName: 'Egyéni szerepkör megnevezése',
      selectRole: 'Válassz szerepkört...',
      event: 'Rendezvény',
      eventPlaceholder: 'Rendezvények keresése...',
      selectEvents: 'Válassz rendezvényeket...',
      confirmSelection: 'Mentés',
      cancelSelection: 'Mégse',
      searchEvents: 'Keresés',
      hasPermissionToAllEvents: 'Összes rendezvény',
      infoToAllEventsSelect:
        'Az aktív, a már archivált és a jövőben létrehozott rendezvényekre egyaránt vonatkozik',
      permissions: {
        title: 'Jogosultságok',
        permissionDetails: 'Jogosultságok szerkesztése',
        event: {
          label: 'Rendezvény',
          submenus: {
            event: {
              label: 'Rendezvény',
              create: 'Létrehozás',
              list: 'Lista',
              edit: 'Szerkesztés',
              limit: 'Limitkezelés',
              history: 'Naplózás',
            },
            statistics: {
              label: 'Statisztika',
              sales: 'Eladás',
              report: 'Riport',
              customizedStatistics: 'Egyéni statisztika',
            },
            marketing: {
              label: 'Marketing',
              coupon: 'Kupon',
            },
            finance: {
              label: 'Pénzügy',
              settlement: 'Elszámolás',
            },
            guestTicket: {
              label: 'Tiszteletjegy',
              invite: 'Meghívás',
              list: 'Vendégek',
              limitManagement: 'Limitkezelés',
              settings: 'Beállítások',
              allUsers: 'Összes felhasználó',
            },
            boxoffice: {
              label: 'Pénztár',
              events: 'Rendezvények',
              settings: 'Beállítások',
            },
            newsletter: {
              label: 'Hírlevél',
            },
          },
        },
        entry: {
          label: 'Beléptetés',
          submenus: {
            entrySystem: {
              label: 'Beléptető',
              list: 'Lista',
              edit: 'Szerkesztés',
              delete: 'Törlés',
            },
          },
        },
        ntak: {
          label: 'NTAK',
          submenus: {
            attraction: {
              label: 'Attrakció',
              listEdit: 'Regisztráció és napló',
            },
          },
        },
        users: {
          label: 'Fiók beállításai',
          submenus: {
            account: {
              label: 'Fiók',
              myAccount: 'Fiókom',
              edit: 'Szerkesztés',
            },
            invitedUsers: {
              label: 'Felhasználók',
              list: 'Lista',
              invite: 'Meghívás',
              edit: 'Szerkesztés',
              delete: 'Törlés',
              history: 'Naplózás',
            },
          },
        },
        confirm: 'Mentés',
        cancel: 'Mégse',
        back: 'Vissza',
      },
      invite: 'Meghív',
      addAccount: 'Fiók hozzáadása',
      addEvents: 'Rendezvény hozzáadása',
      edit: 'Fiók módosítása',
      errorMsg: {
        required: '{{ fieldNameHu }} megadása kötelező.',
        validEmail: 'Email cím formátuma nem megfelelő.',
        requiredPermissions: 'Válassz jogosultságokat!',
      },
    },
    confirmInvitation: {
      succeeded: {
        header: 'Megerősítve!',
        description:
          'Sikeresen megerősítetted a meghívást. Jelenleg egy másik címmel vagy bejelentkezve. Először <1>jelentkezz ki</1>, majd ezt követően jelentkezz be a/az {{ email }} email címeddel.',
      },
      failed: {
        header: 'Sikertelen megerősítés.',
        description: 'Nem sikerült megerősítened a meghívást. Próbáld újra!',
      },
    },
    dialogs: {
      accessDenied: {
        title: 'Hozzáférés megtagadva',
        content: `Az általad megadott email címmel már létezik felhasználó, de nincs jogosultságod szerkeszteni azt.`,
      },
      accessAllowed: {
        title: 'Felhasználó szerkesztése',
        content: `Az általad megadott email címmel már létezik felhasználó. <1>Felhasználó szerkesztése</1>`,
      },
      resendInvitation: {
        title: 'Meghívó újraküldése',
        successContent: `Meghívás sikeresen újraküldve!`,
        failedContent: `Meghívás újraküldése sikertelen. Próbáld újra!`,
      },
      confirm: 'Ok',
    },
    successMsg: {
      successfulInvite: 'Felhasználó sikeresen meghívva',
    },
    errorMsg: {
      failedInvite: 'Felhasználó meghívása sikertelen',
      existingAccount: 'A fiók már létezik.',
      requiredPermissions: 'Válassz jogosultságot!',
    },
  },
  users: {
    title: 'Felhasználók',
    breadcrumb: {
      dashboard: 'Vezérlőpult',
      users: 'Felhasználók',
      list: 'Lista',
    },
    inviteUser: 'Felhasználó meghívása',
    table: {
      tabs: {
        all: 'Összes',
        editable: 'Szerkeszthető',
      },
      toolbar: {
        role: 'Szerepkör',
        search: 'Keresés...',
      },
      header: {
        name: 'Név',
        email: 'Email cím',
        role: 'Szerepkör',
        events: 'Rendezvények',
        isVerified: 'Visszaigazolva',
      },
      access: {
        all: 'Teljeskörű',
        customized: 'Egyéni',
      },
      actions: {
        edit: 'Szerepkör módosítása',
        add: 'Szerepkör hozzáadása',
        delete: 'Felhasználó törlése',
        editEmail: 'Email szerkesztése',
        resendInvitation: 'Meghívó újraküldése',
      },
      footer: {
        dense: 'Tömörítés',
        rowsPerPage: 'Oldalankénti sorok',
      },
      empty: 'Nincs adat',
    },
    userAddRole: {
      addRole: 'Szerepkör hozzáadása',
      addRoleToUser: 'Szerepkör hozzáadása a felhasználóhoz',
      errorMsg: {
        failedCreateRole: 'Szerepkör hozzáadása a felhasználóhoz sikertelen.',
        hasTheSelectedRole: 'A felhasználó már rendelkezik a kiválasztott szerepkörrel.',
        hasHigherLevelRole:
          'A felhasználó már rendlekezik egy magasabb szintű szerepkörrel az összes rendezvényre.',
      },
    },
    userEditRole: {
      editRole: 'Szerepkör szerkesztése',
      deleteRole: 'Szerepkör törlése',
      stepper: {
        chooseRole: 'Válassz szerepkört',
        editRole: 'Szerepkör szerkesztése',
        confirm: 'Megerősítés',
        next: 'Tovább',
        back: 'Vissza',
      },
      selectRole: {
        selectedUserRoles: '{{ lastName }} {{ firstNameHu }} szerepkörei',
        selectedUserRolesPlaceholder: 'Válassz szerepkört...',
      },
      confirmModifications: {
        events: 'Rendezvények:',
        allEvents: 'Összes rendezvény',
      },
      errorMsg: {
        failedRoleUpdate: 'A szerepkör frissítése sikertelen.',
        failedEventsAdd: 'Rendezvények hozzáadása a szerepkörhöz sikertelen.',
        failedEventsDelete: 'Rendezvények eltávolítása a szerepkörből sikertelen.',
      },
    },
    userEditEmail: {
      header: 'Email módosítása és meghívó újraküldése',
      submit: 'Módosít és küld',
    },
    userDeleteRole: {
      confirmationDialog: {
        title: 'Törlés megerősítése',
        roleDeleteContent: `A következő szerepkört szeretnéd törölni: {{ roleName }}. Biztos vagy benne?`,
        lastRoleDeleteContent:
          "Nem tartozik több szerepkör ehhez a felhasználóhoz, ezért a törlés után el fog tűnni a listából. Ha szeretnél ismét szerepkört hozzárendelni ehhez a felhasználóhoz megteheted a 'Meghívás' menüpont alatt.",

        confirm: 'Törlés',
        back: 'Mégsem',
      },
      errorMsg: {
        deleteRoleFailed: 'Kiválasztott szerepkör törlése sikertelen.',
      },
    },
    userDelete: {
      confirmationDialog: {
        title: 'Törlés megerősítése',
        userDeleteContent: `A/az '{{ email }}' email címhez tartozó felhasználót szeretnéd törölni. Biztos vagy benne?`,
        confirm: 'Törlés',
        back: 'Mégsem',
      },
      errorMsg: {
        deleteUserFailed: 'Kiválasztott felhasználó törlése sikertelen.',
      },
    },
    roles: {
      user: 'Felhasználó',
      ingyenesSzervezo: 'Ingyenes szervező',
      szervezo: 'Szervező',
      rendezvenyszervezo: 'Rendezvényszervező',
      penzugy: 'Pénzügy',
      marketing: 'Marketing',
      beleptetoManager: 'Beléptető manager',
      penztaros: 'Pénztáros',
      tiszteletjegyKuldes: 'Tiszteletjegy küldés',
      jegyfogyasKovetes: 'Jegyfogyás követés',
      belepteto: 'Beléptető',
      egyeni: 'Egyéni',
    },
  },
  userHistory: {
    title: 'Naplózás',
    breadcrumb: {
      dashboard: 'Vezérlőpult',
      users: 'Felhasználók',
      history: 'Naplózás',
    },
    table: {
      toolbar: {
        actionType: 'Művelet',
        all: 'Összes',
        search: 'Keresés...',
        from: 'Dátumtól',
        to: 'Dátumig',
      },
      header: {
        user: 'Felhasználó',
        action: 'Művelet',
        modified: 'Módosított',
        created: 'Dátum',
        status: 'Státusz',
      },
      statusSuccess: 'Sikeres művelet',
      statusFailed: 'Sikertelen művelet',
      details: 'Részletek',
      footer: {
        dense: 'Tömörítés',
        rowsPerPage: 'Oldalankénti sorok',
      },
      empty: 'Nincs adat',
    },
    details: {
      user: 'Felhasználó:',
      invitedUser: 'Meghívott felhasználó:',
      newAccount: 'Új fiók',
      modifiedAccount: 'Módosított fiók:',
      deletedAccount: 'Törölt fiók:',
      events: 'Rendezvények:',
      deletedEvents: 'Eltávolított rendezvények:',
      newRole: 'Új szerepkör:',
      allEvents: 'összes',
      info: {
        inviteUser: 'Felhasználó meghívása email cím alapján',
        editPermissions: 'Felhasználó jogosultságának módosítása',
        addEvents: 'Rendezvények hozzáadása meglévő fiókhoz',
        addAccount: 'Új jogosultsági szint hozzáadása',
        deleteEvents: 'Rendezvények törlése meglévő fiókból',
        deleteAccount: 'Jogosultsági szint törlése',
      },
      error: 'Valami hiba történt.',
    },
    actions: {
      inviteUser: 'Felhasználó meghívása',
      editPermissions: 'Jogosultságok módosítása',
      addEvents: 'Rendezvények hozzáadása',
      addAccount: 'Fiók hozzáadása',
      deleteEvents: 'Rendezvények törlése',
      deleteAccount: 'Fiók törlése',
    },
  },
  recommendation: {
    title: 'Kiajánló',
    breadcrumb: {
      dashboard: 'Vezérlőpult',
      recommendation: 'Kiajánló',
    },
    content: {
      header: 'Szolgáltatási tájékoztató',
      ticketingService: {
        header: 'Jegyértékesítési szolgáltatás',
        firstParagraph:
          'A jegyértékesítés szolgáltatásunk kétféle konstrukcióval vehető igénybe – bizományosi fiók és saját fiók.',
        secondParagraph:
          'Szerződni adószámmal rendelkező, aktív cégformával van lehetőség, akár egyéni vállalkozóként is.',
        secondSubParagraph: 'Katás vállalkozások számára elérhető a céges számlák tiltása.',
        thirdParagraph:
          'Szerződés esetén egy TIXA fiókot hozunk létre és határozatlan idejű szerződést kötünk - nem szükséges új rendezvény esetén újra szerződni.',
        fourthParagraph:
          'A TIXA fiók egy saját, jelszóval védett egyedi partnerfelület, ahol a szervező tudja megadni az eseménye részleteit, a jegyértékesítés alapadatait, statisztikákat követni, és kijelölt kapcsolattartó segít a megadott információk alapján elindítani az egyedi linken (oldalon) az értékesítést.',
        fifthParagraph:
          'Szerződés előtt segítünk megismerni a két konstrukciónkat, hogy minél könnyebben kiválaszthasd a számodra megfelelőt.',
      },
      contractForm: {
        commission: 'Bizományosi',
        personal: 'Saját',
      },
      firstSteps: {
        header: 'Indulás',
        nonHuCompanyOrContract: 'Szerződés',
        company: 'Cég',
        launch: 'Fiókélesítés',
        simplePay: 'SimplePay',
        szamlazzHu: 'Számlázz.hu',
        commission: {
          nonHuCompanyOrContract: 'Vállalkozási szerződés',
          company: 'Leginkább ÁFÁS cégek és vállalkozások számára ajánlott',
          launch: '1-2 munkanap',
          simplePay: 'TIXA Hungary Kft. áll velük kapcsolatban',
          szamlazzHu: 'TIXA Hungary Kft. fiókján keresztül állítjuk ki a számlát',
        },
        personal: {
          nonHuCompanyOrContract: 'Szolgáltatási szerződés',
          company: 'AAM és ÁFÁS cégek és vállalkozások számára is ajánlott',
          launch: '7-10 munkanap',
          simplePay: 'A partner cége is szerződik velük, melyben segítünk (1.905 Ft)',
          szamlazzHu: 'A partner fiókján keresztül számlázunk (díjmentes)',
        },
      },
      customerInvoice: {
        header: 'Számlázás vevő felé',
        firstParagraph: 'A TIXA állít ki a vevők felé számlát',
        secondParagraph: 'Minden jegy mellé automatikusan kiállított elektronikus számla készül',
        thirdParagraph: 'Ennek a díját a TIXA fizeti',
        commission: {
          fourthParagraph: 'A TIXA Hungary Kft. jelenik meg, mint számlakibocsátó',
        },
        personal: {
          fourthParagraph: 'A partner cége, vállalkozása jelenik meg, mint számlakibocsátó',
        },
      },
      revenueFromTickets: {
        header: 'Jegybevétel',
        firstParagraph: 'Jegybevétel + kezelési költség',
        commission: {
          secondParagraph: 'TIXA Hungary Kft.-hez érkezik',
          thirdParagraph: 'A vásárló által fizetett kezelési költség 100%-ban a TIXÁ-t illeti',
        },
        personal: {
          secondParagraph: 'A partner cégéhez érkezik',
          thirdParagraph:
            'A vásárló által fizetett kezelési költségen a partner és a TIXA osztozik',
        },
      },
      handlingFee: {
        header: 'Kezelési költség',
        commission: {
          share: 'TIXA 100%',
        },
        personal: {
          share: 'Partner - TIXA',
          shareValue: '50% - 50%',
        },
        content: {
          fixedFee: 'FIX 150 HUF',
          perTransaction: 'tranzakciónként',
          percentageOfTicketPrice: 'Jegyár 3%-a',
          fromGrossValue: 'a vásárolt jegyek bruttó értéke',
        },
      },
      settlement: {
        header: 'Elszámolás',
        firstParagraph: 'Számla ellenében utalással',
        commission: {
          secondParagraph: 'Lezárult rendezvény után',
          thirdParagraph: 'A partner állít ki számlát a TIXÁ-nak a jegybevétel bruttó értékéről',
          fourthParagraph:
            'A TIXA a bruttó jegyárból számított jutalékról állít ki a partner felé számlát',
        },
        personal: {
          secondParagraph:
            'Lezárult rendezvény után vagy bizonyos időszakon belül történt vásárlások után',
          thirdParagraph:
            'A TIXA állít ki számlát a partnernek  a bruttó jegyárból számított jutalékról \n+ a kezelési költség 50%-ról \n+ a postaköltségről (ha volt)',
        },
      },
      partnerExpenses: {
        header: 'A partner költsége',
        firstParagraph: 'A TIXA munkadíja a jegybevétel jutaléka',
        secondParagraph: 'Nincsen csatlakozási díj és más költség',
        thirdParagraph: 'Ha nincs aktív rendezvényed, nincs költsége a TIXA együttműködésnek sem',
        commission: {
          simplePay: {
            header: 'SimplePay',
            content: 'Ez a költség a TIXÁ-t terheli',
          },
        },
        personal: {
          simplePay: {
            header: 'SimplePay',
            accountManagementFee: 'fiókkezelési díj',
            accountManagementFeeValue: '0 Ft',
            joiningFee: 'fiókkezelési díj',
            joiningFeeValue: '1.905 Ft',
            turnoverTax: 'forgalmi díj *',
            turnoverTaxValue: '1.5% + ÁFA',
            turnoverTaxDetails:
              '*a partnert megillető 50% kezelési költség megközelítőleg fedezi ezt',
          },
        },
      },
      personalContact: {
        header: 'Kapcsolattartó',
        content:
          'Saját, kijelölt kapcsolattartód keresheted email-ben és telefonon is, ha bármilyen kérdésed felmerül!',
      },
      customerService: {
        header: 'Ügyfélszolgálat',
        content:
          'Munkanapokon 10 és 16 óra között e-mailes ügyfélszolgálatot biztosítunk a vásárlók számára, ahol a megkeresésekre 24 órán belül válaszolunk.',
      },
      additionalServices: {
        header: 'Kiegészítő szolgáltatások',
        ticketAndAccessControl: {
          header: 'Jegyellenőrzés, beléptetés',
          info: 'vonalkódolvasóval, saját mobilos applikációval',
        },
        onSiteTicketingOptions: {
          header: 'Helyszíni jegyértékesítési lehetőség',
          info: 'pénztár funkció, nyomtatott papírjegy',
        },
        advertisingPackages: 'Hirdetési csomag',
        wristband: 'Karszalag gyártás',
        plasticCard: 'Plasztikkártya gyártás',
      },
    },
    confirmEmailFirst: 'Előbb erősítsd meg az email címed',
    continueWithFreeAccount: 'Folytatás ingyenes fiókkal',
    addCompanyData: 'Cégadatok megadása',
    menuButton: 'Kiajánló',
  },
  offer: {
    title: 'Ajánlatok',
    breadcrumb: {
      dashboard: 'Vezérlőpult',
      offers: 'Ajánlatok',
    },
    account: {
      freeAccount: 'Ingyenes fiók',
      proAccount: 'Pro fiók',
      freeEvent: 'Ingyenes rendezvény',
      paidEvent: 'Fizetős rendezvény létrehozása',
      statistics: 'Eladási statisztikák',
      detailedStatistics: 'Részletes eladási statisztikák',
      finance: 'Pénzügy',
      enrolement: 'Beléptetés',
      guestTicket: 'Tiszteletjegy',
      printing: 'Jegynyomtatás',
    },
    dialog: {
      title: 'Ingyenes szervező',
      content: 'Létrejön egy szervező fiók, amellyel ingyenes rendezvényeket tudsz létrehozni',
      cancel: 'Mégsem',
      create: 'Létrehoz',
    },
    errorMsg: {
      createFreeAccountSucceeded: 'Ingyenes szervezői fiók sikeresen létrehozva',
      createFreeAccountFailed: 'Ingyenes fiók létrehozása sikertelen',
      alreadyHasOrganizer: 'Már rendelkezel szervezői fiókkal',
    },
    continue: 'Létrehozás',
    details: 'Részletek',
  },
  resendConfirmationEmail: {
    header: 'Lejárt a megerősítő email?',
    description:
      'Kérlek add meg a fiókodhoz tartozó email címet és küldünk egy linket az email cím megerősítéséhez',
    back: 'Vissza',
  },
  resetPassword: {
    header: 'Elfelejtetted a jelszavad?',
    description:
      'Add meg a fiókodhoz tartozó email címed és elküldjük az új jelszó igényléséhez szükséges információkat.',
    back: 'Vissza',
  },
  newPassword: {
    header: 'Jelszó megváltoztatása',
    description: 'Add meg a/az {{ email }} email címhez tartozó új jelszót.',
    password: 'Új jelszó',
    confirmPassword: 'Új jelszó megerősítése',
    submit: 'Jelszó megváltoztatása',
    errorMsg: {
      passwordLength: 'A jelszónak minimum 6 karakter hosszúnak kell lennie.',
      passwordRequired: 'Jelszó megadása kötelező.',
      confirmPasswordRequired: 'Jelszó megerősítése kötelező.',
      passwordMatch: 'Nem egyezik meg a két jelszó.',
    },
  },
  sendEmail: {
    email: 'Email cím',
    submit: 'Kérés küldése',
    emailSent: 'Email elküldve!',
    sentDescription:
      'Az emailt sikeresen elküldtük a/az {{ email }} email címre. \nKérjük, ellenőrizd a postaládád!',
    errorMsg: {
      validEmail: 'Email cím formátuma nem megfelelő',
      required: 'Email megadása kötelező',
      sendConfirmationEmailFailed: 'Megerősítő email küldése sikertelen',
      sendPasswordRecoveryEmailFailed: 'Jelszó helyreállító email küldése sikertelen',
    },
  },
  expiredToken: {
    header: 'Lejárt token!',
    newPasswordDescription: 'Lejárt token! A jelszó megváltoztatásához küldd újra a kérést.',
    emailConfirmationDescription:
      'Lejárt token! Az email cím megerősítéséhez kérlek küldd újra az emailt.',
    inviteConfirmationDescription:
      'Lejárt token! A megerősítő link újraküldéséhez vedd fel a kapcsolatot az email feladójával.',
    resend: 'Kérés újraküldése',
  },
  confirmedEmail: {
    header: 'Megerősítve!',
    description:
      'Az email cím már meg lett erősítve. Mostantól szabadon használhatod az oldal funkcióit.',
    btn: 'Irány az oldal',
  },
  table: {
    tabs: {
      active: 'Aktív',
      archive: 'Archív',
    },
    toolbar: {
      search: 'Keresés...',
    },
    selection: {
      selected: 'kiválasztva',
    },
    content: {
      noData: 'Nincs adat',
      noDataByFilters: 'Nincs a szűrésnek megfelelő adat.',
      selectFilter: 'Adj meg szűrési feltételeket.',
    },
    footer: {
      dense: 'Tömörítés',
      rowsPerPage: 'Oldalankénti sorok',
    },
  },
  menu: {
    event: {
      label: 'Rendezvény',
      event: {
        label: 'Rendezvény',
        create: 'Létrehozás',
        list: 'Lista',
        limit: 'Limitkezelés',
        history: 'Napló',
      },
      statistics: {
        label: 'Statisztika',
        report: 'Riport küldés',
        sales: 'Eladás',
        customizedStatistics: 'Egyéni statisztika',
      },
      marketing: {
        label: 'Marketing',
        coupon: 'Kupon',
        statistics: 'Statisztika',
      },
      finance: {
        label: 'Pénzügy',
        settlement: 'Elszámolás',
      },
      guestTicket: {
        label: 'Tiszteletjegy',
        invite: 'Meghívás',
        list: 'Vendégek',
        limit: 'Limitkezelés',
        settings: 'Beállítások',
      },
      boxoffice: {
        label: 'Pénztár',
        events: 'Rendezvények',
        settings: 'Beállítások',
      },
      newsletter: 'Hírlevél',
    },
    entry: {
      label: 'Beléptetés',
      entrySystem: {
        label: 'Beléptető',
        list: 'Lista',
        edit: 'Szerkesztés',
        delete: 'Törlés',
      },
    },
    ntak: {
      label: 'NTAK',
      attraction: {
        label: 'Attrakció',
        registration: 'Regisztráció',
        list: 'Napló',
      },
    },
    accountSettings: {
      label: 'Fiók beállításai',
      account: {
        label: 'Fiók',
        profile: 'Profil',
        companyData: 'Cégadatok',
      },
      invitedUsers: {
        label: 'Felhasználók',
        invite: 'Meghívás',
        list: 'Lista',
        history: 'Napló',
      },
    },
    services: {
      label: 'Szolgáltatások',
      offers: 'Ajánlatok',
    },
  },
  emailConfirmationMsg:
    'Az email címed nincs megerősítve! Ellenőrizd a postaládád és erősítsd meg az email címed. Ha nem találod ',
  resendEmail: {
    link: 'küldd újra!',
    successMsg: 'Az emailt sikeresen elküldtük!',
    errorMsg: 'Az emailt nem sikerült elküldeni. Próbáld újra!',
    dialogTitle: 'Email cím megerősítése',
  },
  searchBar: {
    placeholder: 'Keresés...',
    submit: 'Keresés',
  },
  organizerSelectLabel: 'Szervező',
  contactPopover: {
    contact: 'Kapcsolattartód',
    copy: 'Másol',
    copied: 'Másolva',
  },
  accountPopover: {
    horizontalNav: 'Menü pozíció',
    home: 'Home',
    profile: 'Profil',
    settings: 'Beállítások',
    logout: 'Kijelentkezés',
    errorMsg: {
      logoutFailed: 'Sikertelen kijelentkezés!',
    },
  },
  searchableSelect: {
    label: {
      event: 'Rendezvény',
      user: 'Felhasználó',
    },
    all: 'Összes',
    search: 'Keresés',
    confirm: 'Mentés',
    cancel: 'Mégse',
  },
  timePeriod: {
    label: 'Időszak',
    all: 'Összes',
    currentDay: 'Mai nap',
    currentWeek: 'Aktuális hét',
    previousWeek: 'Előző hét',
    currentMonth: 'Aktuális hónap',
    previousMonth: 'Előző hónap',
    currentYear: 'Aktuális év',
    previousYear: 'Előző év',
    customized: 'Egyéni',
    from: 'Dátumtól',
    to: 'Dátumig',
    errorMsg: {
      sales: 'Válassz dátumot az értékesítés kezdete és értékesítés vége/jelenlegi dátum között',
    },
  },
  pageTitles: {
    home: 'Kezdőlap',
    login: 'Bejelentkezés',
    register: 'Regisztráció',
    passwordReset: 'Új jelszó igénylés',
    passwordNew: 'Új jelszó',
    eventCreate: 'Rendezvény: Létrehozás',
    eventEdit: 'Rendezvény: Szerkesztés',
    eventLimitManagement: 'Rendezvény: Limitkezelés',
    eventHistory: 'Rendezvény: Naplózás',
    eventList: 'Rendezvény: Lista',
    statisticsSales: 'Statisztika: Eladás',
    statisticsDetails: 'Statisztika: Részletek',
    statisticsReport: 'Statisztika: Riport',
    statisticsCustomized: 'Statisztika: Egyéni statisztika',
    marketingCoupon: 'Marketing: Kupon',
    marketingCouponCode: 'Marketing: Kuponkód',
    marketingCouponStatistics: 'Marketing: Kupon statisztika',
    accountProfile: 'Fiók: Profil',
    accountCompanyData: 'Fiók: Cégadatok',
    userInvite: 'Felhasználó: Új felhasználó meghívása',
    userList: 'Felhasználó: Lista',
    userHistory: 'Felhasználó: Naplózás',
    offers: 'Ajánlatok',
    recommendation: 'Kiajánló',
    invitationConfirm: 'Meghívás megerősítése',
    invationtConfirmSucceeded: 'Sikeres megerősítés',
    invitationConfirmFailed: 'Sikertelen megerősítés',
    emailConfirm: 'Email megerősítés',
    emailConfirmed: 'Email megerősítve',
    emailSent: 'Email elküldve',
    tokenExpired: 'Lejárt token',
    report: 'Riport',
    ntakAttractionRegistration: 'NTAK Attrakció: Regisztráció',
    ntakAttractionList: 'NTAK Attrakció: Napló',
    boxOfficeEvents: 'Pénztár: Rendezvények',
    boxOfficeSettings: 'Pénztár: Beállítások',
    reportUnsubscribe: 'Leiratkozás',
    financeSettlement: 'Pénzügy: Elszámolás',
    guestInvite: 'Tiszteletjegy: Meghívás',
    guestlist: 'Tiszteletjegy: Vendégek',
    guestStatistics: 'Tiszteletjegy: Statisztika',
    guestDetails: 'Tiszteletjegy: Vendég részletek',
    guestSettings: 'Tiszteletjegy: Beállítások',
    guestPreview: 'Tixa | Tiszteletjegy: Előnézet',
    replyToConfirmation: 'Reply-to megerősítés',
    guestLimitManagement: 'Tiszteletjegy: Limitkezelés',
    404: '404 Oldal nem található',
    500: '500 Szerverhiba',
  },
  notFoundRecord: {
    header: {
      event: 'Rendezvény nem található',
      coupon: 'Kupon nem található',
      customizedStatistics: 'Egyéni statisztika nem található',
      guestInvitation: 'Vendég nem található',
    },
    description:
      '{{ type }} (ID: {{ id }}) nem található a következő szervezőnél: {{ organizerName }}.',
  },
  page404: {
    title: 'Sajnáljuk, az oldal nem található.',
    content:
      'Sajnáljuk, de az általad keresett oldal nem található. Bizonyosodj meg róla, hogy a megfelelő url-t adtad meg.',
    goHome: 'Kezdőlap',
  },
  page500: {
    title: '500 Szerverhiba',
    content: 'Hiba lépett fel. Kérjük próbálkozz meg később.',
    goHome: 'Kezdőlap',
  },
  snackbar: {
    saveSucceeded: 'Sikeres mentés.',
    saveFailed: 'Mentés sikertelen.',
    deleteSucceeded: 'Sikeres törlés.',
    deleteFailed: 'Törlés sikertelen.',
    actionSucceeded: 'Sikeres művelet.',
    actionFailed: 'Sikertelen művelet.',
    deletedRole:
      "'{{ role }}' szerepkörödet törölték! Ezentúl nem férsz hozzá a törölt szerepkörhöz tartozó menüpontokhoz.",
  },
  dialog: {
    autoLogout: {
      title: 'Figyelem!',
      content:
        "A munkamenet hamarosan lejár. Ha folytatni szeretnéd, kattints a 'Bejelentkezve maradok' gombra, ellenkező esetben a 'Kijelentkezés' gombbal tudsz biztonságosan kilépni.",
      logout: 'Kijelentkezés',
      stayLoggedIn: 'Bejelentkezve maradok',
    },
    deletedOrganizer: {
      title: 'Törölt szervező',
      content: 'Szerveződet törölték. Kérjük, válassz egy másik szervezőt az alábbi listából.',
      deletedAllOrganizerContent:
        'Szerveződet törölték. Vedd fel a kapcsolatot a szervezővel vagy hozz létre saját szervezőt ha továbbra is használni szeretnéd az oldal funkcióit.',
    },
    delete: {
      title: 'Törlés megerősítése',
      content: "A következő {{ type }} szeretnéd törölni: '{{ name }}'. Biztos vagy benne?",
      action: {
        cancel: 'Mégse',
        delete: 'Törlés',
      },
    },
  },
  errorMsg: {
    required: '{{ fieldNameHu }} megadása kötelező.',
    invalidLoginData: 'Érvénytelen bejelentkezési adatok',
    emailInUse: 'Ezzel az email címmel már regisztráltak.',
    mustRegisterBeforeLogin: 'Belépés előtt regisztrálj az emailben elküldött meghívó alapján!',
    mustRegisterBasedInvitation: 'Regisztrálj az emailben elküldött meghívó alapján!',
    internalError: 'Szerverhiba',
    databaseError: 'Adatbázis hiba',
  },
};

export default hu;
