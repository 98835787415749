export enum TableEnum {
  USERS = 'users',
  USER_HISTORY = 'user-history',
  EVENTS = 'events',
  EVENT_HISTORY = 'event-history',
  EVENT_LIMIT_MANAGEMENT = 'event-limit-management',
  STATISTICS_SALES = 'statistics-sales',
  STATISTICS_COUPON = 'statistics-coupon',
  REPORTS = 'reports',
  NTAK_ATTRACTIONS = 'ntak-attractions',
  OFFLINE_TICKETS = 'onsite-tickets',
  BOXOFFICE_LISTING = 'boxoffice-listing',
  SETTLEMENT = 'settlement',
  DETAILED_SETTLEMENT_ONLINE = 'detailed-settlement-online',
  DETAILED_SETTLEMENT_OFFLINE = 'detailed-settlement-offline',
  DETAILED_SETTLEMENT_OVERVIEW = 'detailed-settlement-overview',
  DETAILED_SETTLEMENT_TRANSFER_FEE = 'detailed-settlement-transfer-fee',
  DETAILED_SETTLEMENT_REFUND = 'detailed-settlement-refund',
  DETAILED_SETTLEMENT_HANDLING_FEE = 'detailed-settlement-handling-fee',
  DETAILED_SETTLEMENT_GUEST_TICKET = 'detailed-settlement-guest-ticket',
  DETAILED_SETTLEMENT_COUPON = 'detailed-settlement-coupon',
  GUEST_INVITE = 'guest-invite',
  COUPON_LIST = 'coupon-list',
  COUPON_ADD_EVENT = 'coupon-add-event',
  CUSTOMIZED_STATISTICS = 'customized-statistics',
  GUESTLIST = 'guestlist',
  GUESTINVITESEATMAPLIST = 'guest-invite-seat-list',
}
