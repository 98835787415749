import React, { ReactNode, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import MenuItem from '@mui/material/MenuItem';
import useLocales from 'src/hooks/useLocales';
import { TableMoreMenu } from 'src/components/table';
import Iconify from 'src/components/Iconify';
import { OfflineTicketType } from 'src/@types/OfflineTickets';
import DeleteDialog from 'src/sections/@dashboard/event/new-event/DeleteDialog';

interface OfflineTableMoreMenuProps {
  handleEditRow: (
    event: React.MouseEvent<HTMLLIElement>,
    offlineTicket: OfflineTicketType,
    setOpenMenuActions: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  ) => void;
  offlineTicket: OfflineTicketType;
  deleteOfflineTicket: (offlineTicket: OfflineTicketType) => void;
}

const OfflineTableMoreMenu: React.FC<OfflineTableMoreMenuProps> = ({
  handleEditRow,
  offlineTicket,
  deleteOfflineTicket,
}) => {
  const { translate } = useLocales();
  const [openMenuActions, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteDialogContent, setDeleteDialogContent] = useState<string | ReactNode>('');

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOpenMenuActions(event.currentTarget);
  };

  const handleMenuClose = () => {
    setOpenMenuActions(null);
  };

  const HandleDeleteRow = (offlineTicket: OfflineTicketType) => {
    deleteOfflineTicket(offlineTicket);
    setOpenMenuActions(null);
    setOpenDeleteDialog(false);
  };

  const handleOpenDeleteDialog = (
    offlineTicket: OfflineTicketType,
    setOpenMenuActions: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  ) => {
    setDeleteDialogContent(
      translate('ntak.boxoffice.offlineTicket.actions.ticketDeletionAllowed', {
        ticketName: offlineTicket.ticketTypeName,
      })
    );
    setOpenDeleteDialog(true);
    setOpenMenuActions(null);
  };

  return (
    <>
      <TableCell align="right" component="th" scope="row">
        <TableMoreMenu
          open={openMenuActions}
          width={160}
          onOpen={(event) => {
            event.stopPropagation();
            handleMenuOpen(event);
          }}
          onClose={handleMenuClose}
          actions={
            <>
              <MenuItem
                onClick={(event) => handleEditRow(event, offlineTicket, setOpenMenuActions)}
              >
                <Iconify icon={'eva:edit-fill'} />
                {translate('eventList.table.actions.edit')}
              </MenuItem>
              <MenuItem
                onClick={() => handleOpenDeleteDialog(offlineTicket, setOpenMenuActions)}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon={'eva:trash-2-outline'} />
                {translate('eventList.table.actions.delete')}
              </MenuItem>
            </>
          }
        />
      </TableCell>
      <DeleteDialog
        open={openDeleteDialog}
        dataTestId={'deleteDialog'}
        onClose={() => setOpenDeleteDialog(false)}
        deleteDialogContent={deleteDialogContent}
        isDeleteAllowed={true}
        handleDelete={() => HandleDeleteRow(offlineTicket)}
      />
    </>
  );
};

export default OfflineTableMoreMenu;
