import { Language } from './location';

export type Category = {
  name: string;
  nameEnglish: string | null;
  id: number;
  default: boolean;
};

export interface GuestInvitationItem {
  ticketTypeName: string;
  ticketTypeNameEnglish: string | null;
  ticketTypeId: number;
  quantity: number;
  seatId?: string | null;
}

export type GuestInvitationItemWithoutNames = Omit<
  GuestInvitationItem,
  'ticketTypeName' | 'ticketTypeNameEnglish'
>;

export interface IPostGuestInvitation {
  guestCategoryId: number;
  partnerUserId: number | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  companyName: string | undefined;
  email: string;
  language: string;
  guestTicketComment: string | undefined;
  guestInvitationItems: GuestInvitationItemWithoutNames[];
  sendingEmail: boolean;
}

export type GuestFormValuesProps = {
  guestCategoryId: number;
  guestInvitationItems: GuestInvitationItemWithoutNames[];
  firstName: string | undefined;
  lastName: string | undefined;
  companyName: string | undefined;
  email: string;
  language: string;
  guestTicketComment: string | undefined;
};

export type EventGuestStatistics = {
  eventId: number;
  eventName: string;
  eventEnglishName: string | null;
  eventLocation: string;
  eventStartDate: string;
  groups: Groups[];
  summary: number;
};

export type Groups = {
  groupId: number;
  groupName: string;
  groupEnglishName: string | null;
  groupItems: GroupItem[];
  summary: number;
};

export type GroupItem = {
  groupItemId: number;
  groupItemName: string;
  groupItemEnglishName: string | null;
  summary: number;
};

export type GuestInvitationWithRelatedData = {
  id: number;
  date: string;
  firstName: string;
  lastName: string;
  email: string;
  event: {
    id: number;
    name: string;
    nameEnglish: string;
    date: string;
    startingTime: string;
  };
  partnerUser: {
    id: number;
    firstName: string;
    lastName: string;
  };
  guestInvitationItems: [
    {
      id: number;
      quantity: number;
      ticketTypeId: number;
      ticketTypeName: string;
      ticketTypeEnglishName: string;
    }
  ];
};

export type GuestTicketWithTicketTypeName = {
  id: number;
  barcode: number;
  link: string;
  sent: boolean;
  seatName: string | null;
  rowName: string | null;
  sectionName: string | null;
  sectionNameEnglish: string | null;
  ticketTypeName: string;
  ticketTypeEnglishName: string;
};

export type GuestDetailsWithRelatedData = {
  id: number;
  partnerUserId: number;
  eventId: number;
  guestCategoryId: number;
  firstName: string | null;
  lastName: string | null;
  companyName: string | null;
  email: string;
  language: Language;
  guestTicketComment: string;
  date: string;
  partnerUser: {
    firstName: string;
    lastName: string;
  };
  event: {
    name: string;
    nameEnglish: string;
    date: string;
    startingTime: string;
    hasSeatMap: boolean;
  };
  guestCategory: {
    name: string;
    nameEnglish: string | null;
  };
};

export enum GuestHistoryAction {
  ALL = 'all',
  MODIFY_EMAIL = 'modifyEmail',
  REGENERATE = 'regenerate',
  RESEND = 'resend',
  DELETE = 'delete',
}

export type GuestHistory = {
  id: number;
  partnerUserId: number;
  partnerUser: {
    firstName: string;
    lastName: string;
  };
  action: GuestHistoryAction;
  date: string;
  details: string;
};

export type GuestSettingsResponse = {
  partnerUserId: number;
  organizerId: number;
  replyTo: string;
  emailContent: string;
  emailContentEnglish: string;
  replyToConfirmed: boolean;
  defaultEmailContent?: string | null;
  defaultEmailContentEnglish?: string | null;
};

export enum DynamicContentHu {
  SURNAME = '#vezetéknév',
  FIRST_NAME = '#keresztnév',
  ORGANIEZR = '#szervező',
  EVENT = '#rendezvény',
  CATEGORY = '#kategória',
}

export enum DynamicContentEn {
  SURNAME = '#surname',
  FIRST_NAME = '#firstname',
  ORGANIEZR = '#organizer',
  EVENT = '#event',
  CATEGORY = '#category',
}

export enum GuestSettingsLevel {
  USER = 'user',
  ORGANIZER = 'organizer',
}
