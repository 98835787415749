import React, { Fragment, useMemo } from 'react';
import { useAppSelector } from 'src/redux/hook';
import useAuth from 'src/hooks/useAuth';
import { EventData } from 'src/@types/event';
import { PagePermissionEnum } from 'src/@types/auth';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import Scrollbar from 'src/components/Scrollbar';
import { TableHeadCustom, TableNoData } from 'src/components/table';
import useLocales from 'src/hooks/useLocales';
import useTable, { OrderEnum } from 'src/hooks/useTable';
import { useGetRolesQuery } from 'src/redux/api/userAPI';
import { TableEnum } from 'src/components/table/type';
import OfflineTicketTableToolbar from './OfflineTicketTableToolbar';
import { OfflineTicketType } from 'src/@types/OfflineTickets';
import useResponsive from 'src/hooks/useResponsive';
import OfflineticketTableMoreMenu from './OfflineticketTableMoreMenu';
import AmountWithCurrency from 'src/components/AmountWithCurrency';

interface OfflineTicketEditorTableProps {
  event: EventData;
  offlineTickets: OfflineTicketType[];
  handleEditRow: (
    event: React.MouseEvent<HTMLLIElement>,
    offlineTicket: OfflineTicketType,
    setOpenMenuActions: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  ) => void;
  deleteOfflineTicket: (offlineTicket: OfflineTicketType) => void;
}

const OfflineTicketEditorTable: React.FC<OfflineTicketEditorTableProps> = ({
  event,
  offlineTickets,
  handleEditRow,
  deleteOfflineTicket,
}) => {
  const { translate, currentLang } = useLocales();
  const isMobile = useResponsive('down', 'sm');

  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({
    defaultDense: 'true',
    defaultOrderBy: 'name',
    defaultOrder: 'asc' as OrderEnum,
  });

  const { user } = useAuth();
  const currentOrganizer = useAppSelector((state) => state.userOrganizers.currentUserOrganizer);

  const { data: userRoles } = useGetRolesQuery(
    {
      userId: user?.id,
      organizerId: currentOrganizer?.id,
    },
    { skip: !user || !currentOrganizer }
  );

  const isNotFound = offlineTickets.length === 0;

  const hasPermissionToThisPageRoles = useMemo(
    () =>
      userRoles?.filter((role) =>
        role.permissions.includes(PagePermissionEnum.NTAK_BOXOFFICE_EVENTS)
      ),
    [userRoles]
  );

  const showOfflineTicketTableToolbar =
    hasPermissionToThisPageRoles && hasPermissionToThisPageRoles.length > 1;

  const TABLE_HEAD = useMemo(
    () => [
      {
        id: 'ticketTypeName',
        label: translate('ntak.boxoffice.offlineTicket.name'),
        width: 50,
        align: 'left',
      },
      {
        id: 'ticketPrice',
        label: translate('ntak.boxoffice.offlineTicket.price'),
        width: 20,
        align: 'left',
      },
      {
        id: 'limit',
        label: translate('ntak.boxoffice.offlineTicket.quantity'),
        width: 2,
        align: 'left',
      },
      { id: 'menu', label: '', width: 2, align: 'right' },
    ],
    [translate]
  );

  return (
    <>
      {showOfflineTicketTableToolbar && (
        <OfflineTicketTableToolbar hasPermissionToThisPageRoles={hasPermissionToThisPageRoles} />
      )}
      <Scrollbar>
        <TableContainer
          sx={{
            minWidth: 500,
            position: 'relative',
            overflow: 'unset',
            marginTop: showOfflineTicketTableToolbar ? 0 : 4,
          }}
        >
          <Table size={isMobile ? 'small' : 'medium'}>
            <TableHeadCustom table={TableEnum.OFFLINE_TICKETS} headLabel={TABLE_HEAD} />
            <TableBody>
              {offlineTickets
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((offlineTicket, index) => (
                  <Fragment key={offlineTicket.id}>
                    <TableRow>
                      <TableCell>{offlineTicket.ticketTypeName}</TableCell>
                      <TableCell>
                        <AmountWithCurrency amount={offlineTicket.ticketPrice} />
                      </TableCell>
                      <TableCell>{offlineTicket.limit}</TableCell>
                      <OfflineticketTableMoreMenu
                        handleEditRow={handleEditRow}
                        offlineTicket={offlineTicket}
                        deleteOfflineTicket={deleteOfflineTicket}
                      />
                    </TableRow>
                  </Fragment>
                ))}
              {<TableNoData isNotFound={isNotFound} dense={isMobile} />}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={offlineTickets.length ?? 0}
          labelRowsPerPage={translate('eventList.table.footer.rowsPerPage')}
          labelDisplayedRows={(page) => {
            const { from, to, count } = page;

            return currentLang.value === 'hu'
              ? `${from}–${to} / ${count !== -1 ? count : `more than ${to}`}`
              : `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
          }}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
    </>
  );
};

export default OfflineTicketEditorTable;
